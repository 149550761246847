import { routes } from 'app/routes';
import { doQuery } from 'base/GraphQLClient';
import moment from 'moment';
import { getFormattedDate } from 'utilities/DateUtils';
import { IHotelMetrics, IProperties } from './GroupDashboard.types';
import {
  GET_FILTER_COUNT_QUERY,
  GET_PROPERTY_METRICS_QUERY,
  PROPERTY_LISTING_QUERY,
  UNREAD_CHAT_SESSION,
  getInternalCalendarGetLinkage,
} from './GroupDashboardGraphQueries';
import { REJECTED_PROPERTY_STATES } from 'group-property/modules/onboarding/constants';
import { showError } from 'base/APIClient';
import { getParsedErrorMessageFromGraphql, getValue } from 'utilities/Utils';
import { FINANCE_AND_LEGAL_DUPLICATION_COMPLETED } from '../duplicate-listing/DuplicateListing.contansts';
import SENTRY from 'utilities/Sentry';

export const getProperties = (showGroup): Promise<IProperties> => {
  return new Promise((resolve, reject) => {
    const query = PROPERTY_LISTING_QUERY;
    const variables = {
      endpointRequestData: {
        listingPropertyRequests: {
          activeListingRequests: {
            modelType: 'modelType1',
          },
        },
      },
    };

    return doQuery(query, variables)
      .then(result => {
        const properties = getValue(result, 'data.fetchPropertyListingData');

        if (properties?.success) {
          const inProgressData = [];
          const { rejectedData, underReviewData } = (
            properties.progressData ?? []
          ).reduce(
            (res, data) => {
              if (REJECTED_PROPERTY_STATES.includes(data.propertyState)) {
                res.rejectedData.push(data);
              } else {
                res.underReviewData.push(data);
              }
              return res;
            },
            { rejectedData: [], underReviewData: [] },
          );
          const { duplicationCompleted, underDuplication, otherDraft } = (
            properties.draftData ?? []
          ).reduce(
            (
              {
                duplicationCompleted: _duplicationCompleted,
                underDuplication: _underDuplication,
                otherDraft: _otherDraft,
              },
              property,
            ) => {
              if (
                property.onboardingDetails.onboardingStage ===
                  FINANCE_AND_LEGAL_DUPLICATION_COMPLETED ||
                (!property.duplicationDetails.duplicationInProgress &&
                  property.duplicationDetails.parentHotelCode !== '')
              ) {
                _duplicationCompleted.push(property);
              } else if (property.duplicationDetails.duplicationInProgress) {
                _underDuplication.push(property);
              } else {
                _otherDraft.push(property);
              }
              return {
                duplicationCompleted: _duplicationCompleted,
                underDuplication: _underDuplication,
                otherDraft: _otherDraft,
              };
            },
            { duplicationCompleted: [], underDuplication: [], otherDraft: [] },
          );

          inProgressData.push(...duplicationCompleted);
          inProgressData.push(...underDuplication);

          if (properties.progressData && showGroup)
            inProgressData.push(...rejectedData);

          inProgressData.push(...otherDraft);

          if (properties.progressData && showGroup)
            inProgressData.push(...underReviewData);

          const activeList = properties?.activeData ?? [];
          const inActiveList = properties?.inactiveData ?? [];
          const allProperties: IProperties = {
            inProgress: inProgressData,
            active: activeList,
            inActive: inActiveList,
          };
          resolve(allProperties);
        } else {
          showError({
            error: getParsedErrorMessageFromGraphql(result.error),
          });
          reject(properties.error);
        }
      })
      .catch(reject);
  });
};

export const getPropertyMetrics = (
  hotelCodes,
): Promise<{
  hotelMetrics: { [key: string]: IHotelMetrics };
  // todaysMetrics: ITodaysMetrics;
  counts: { [key: string]: number };
}> => {
  const oneYearInPast = moment().subtract(1, 'years');
  const todaysDate = moment();
  const tomorrowsDate = moment().add(1, 'days');
  const oneYearInFuture = moment().add(1, 'years');

  const variables = {
    groupChat: {
      unreadChatCount: {},
    },
    endpointRequestData: {
      guestChatBulkHotelSessions: {
        pendingGuestChatReplies: {
          endpointId: 30,
          body: {
            hotelcodes: hotelCodes,
            brand: ['all'],
          },
        },
      },
    },
    endpointRequestDataGI: {
      analyticsDocuments: {
        rnrRatingInfo: {
          endpointId: 10,
          body: {
            category: 'Document',
            pipeline: 'Reviews',
            include: ['reviewOnlyCount'],
            comp_data: 'false',
            date_type: 'createdOn',
            hotel_codes: hotelCodes,
            brand: ['goibibo'],
            count: 1,
            sort: {
              field: 'createdOn',
              order: 'desc',
            },
          },
        },
      },
    },
    endpointBusinessLogicData: {
      altaccoGetTodaysBookingInfo: {
        fromDate: getFormattedDate(todaysDate, 'MM/DD/YYYY'),
        toDate: getFormattedDate(todaysDate, 'MM/DD/YYYY'),
        hotelCodes: hotelCodes,
      },
      altaccoGetUpcomingBookingInfo: {
        fromDate: getFormattedDate(tomorrowsDate, 'MM/DD/YYYY'),
        toDate: getFormattedDate(oneYearInFuture, 'MM/DD/YYYY'),
        hotelCodes: hotelCodes,
      },
      altaccoGetRTBPendingRequests: {
        newPendingRTBRequestsStartTime: `${getFormattedDate(
          oneYearInPast,
          'YYYY-MM-DD',
        )} 13:59:59`,
        newPendingRTBRequestsEndTime: `${getFormattedDate(
          todaysDate,
          'YYYY-MM-DD',
        )} 23:59:59`,
        hotelCodes: hotelCodes,
      },
    },
  };

  return new Promise((resolve, reject) => {
    return doQuery(GET_PROPERTY_METRICS_QUERY, variables, {
      useLoader: false,
      showError: false,
    })
      .then(res => {
        const hotelMetrics: { [key: string]: IHotelMetrics } = {};
        // let todaysMetrics: ITodaysMetrics = {};
        const counts: { [key: string]: number } = {};

        const bookingRequestsData =
          res?.data?.pendingRequests?.rtbBookings ?? [];
        bookingRequestsData.forEach(data => {
          hotelMetrics[data.hotelCode] = { bookingRequests: data.count };
        });

        const unreadChatData = res.data.userLevel.unreadChatCount;
        const ratingsDataGI = res.data.ratingDataGI.rnrRatingInfo.data;
        // const ratingsDataMMT = res.ratingDataMMT.rnrRatingInfo.data;

        Object.keys(hotelMetrics).forEach(key => {
          if (key in ratingsDataGI) {
            hotelMetrics[key].totalReviewsGI =
              ratingsDataGI[key][0].reviewOnlyCount;
          } else {
            hotelMetrics[key].totalReviewsGI = 0;
          }

          if (key in unreadChatData.hotels_unread_count) {
            hotelMetrics[key].unreadChat =
              unreadChatData.hotels_unread_count[key];
          } else {
            hotelMetrics[key].unreadChat = 0;
          }
        });

        // const todaysBookingsData = res.todaysBookings;
        // todaysMetrics['checkIns'] = todaysBookingsData.checkIns.reduce(
        //   (sum, a) => sum + a.count,
        //   0
        // );
        // todaysMetrics['checkOuts'] = todaysBookingsData.checkOuts.reduce(
        //   (sum, a) => sum + a.count,
        //   0
        // );
        // todaysMetrics['stayIns'] = todaysBookingsData.stayIns.reduce(
        //   (sum, a) => sum + a.count,
        //   0
        // );

        // const upcomingBookingsData = res.upcomingBookings.upcomingBookings;
        // upcomingBookingsData.map(
        //   data => (hotelMetrics[data.hotelCode].upcomingBookings = data.count)
        // );

        counts[routes.groupchat.all] = unreadChatData.total_unread_message;

        const response = {
          hotelMetrics: hotelMetrics,
          // todaysMetrics: todaysMetrics,
          counts: counts,
        };

        resolve(response);
      })
      .catch(reject);
  });
};

export const getUnreadSessionCounts = () => {
  const variable = {
    rawInput: {
      unreadChatCount: {},
    },
  };

  return new Promise((resolve, reject) => {
    return doQuery(UNREAD_CHAT_SESSION, variable, {
      useLoader: false,
      showError: false,
    })
      .then(res => {
        const data =
          res.data.altAccoSSOUserAssociatedEndpoints?.unreadChatCount;
        if (data?.success) {
          resolve({
            totalUnreadSession: data?.total_unread_session,
          });
        }
      })
      .catch(reject);
  });
};

export const getUnreadChatCounts = () => {
  const variable = {
    rawInput: {
      messageFilterCount: {},
    },
  };

  return new Promise((resolve, reject) => {
    return doQuery(GET_FILTER_COUNT_QUERY, variable, {
      useLoader: false,
      showError: false,
    })
      .then(res => {
        const data =
          res.data.altAccoSSOUserAssociatedEndpoints?.messageFilterCount;
        if (data?.success) {
          resolve({
            totalUnreadCount: data?.total_unread,
          });
        }
      })
      .catch(reject);
  });
};

export const getInternalCalendarGetLinkageVariable = () => ({
  rawEndpointsRequestData: {
    internalCalendarGetLinkage: {
      modelType: 'modelType1',
      urlParams: {
        hotelCode: '',
      },
    },
  },
});

export const fetchInternalCalSyncData = async () => {
  try {
    const res = await doQuery(
      getInternalCalendarGetLinkage,
      getInternalCalendarGetLinkageVariable(),
    );
    if (res?.data?.rawEndpoint?.internalCalendarGetLinkage?.modelType1) {
      return res.data.rawEndpoint.internalCalendarGetLinkage.modelType1;
    }
    return {
      success: false,
    };
  } catch (error) {
    SENTRY.logError(error);
    return {
      success: false,
    };
  }
};
