import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HostWebMigrationOverlay from 'modules/hostweb-migration/HostWebMigrationOverlay';
import { Button, Typography } from 'connect-web-ui';
import { useLangStrings } from '../../../utilities/CustomHooks';
import { HotelContext } from '../../../utilities/Context';
import { IHotelDashboard } from '../Dashboard.types';

import { legacyPushToGTM } from 'utilities/gtm';
import { GAFormatting } from 'utilities/Utils';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(254.55deg, #7C4EFF 1.88%, #3543BF 100%)',
    boxShadow: '0px 2px 5px rgba(1, 16, 34, 0.1)',
    borderRadius: '4px',
    color: '#ffffff',
  },
  subTitle: {
    lineHeight: '18px',
  },
  button: {
    background: '#ffffff',
    color: '#3543bf',
    borderRadius: '6px',
    '&:hover': {
      background: '#ffffff',
    },
  },
});

type Props = {
  gtmLabel: string;
  hotel: IHotelDashboard;
  onSectionLoadUpdate: (success: boolean, gtmLabel: string) => void;
};

export const HostWebMigration = (props: Props) => {
  const { gtmLabel, hotel, onSectionLoadUpdate } = props;

  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const [STRINGS] = useLangStrings<'HostWebMigration'>('HostWebMigration');
  const classes = useStyles();

  const {
    currentHotel: { hostweb_migration: migrationStatus },
  } = useContext(HotelContext);
  const show = migrationStatus === 'eligible';

  const handlePersuasion = () => {
    legacyPushToGTM({
      eventCategory: 'web_dashboard',
      eventAction: 'host_transition_persuasion',
      omnitureData: {
        event: 'ctaClick',
        name: GAFormatting(STRINGS.PERSUASION.CTA),
      },
      eventLabel: 'Know more',
    });
    setShowOverlay(true);
  };

  useEffect(() => {
    if (show) {
      legacyPushToGTM({
        eventCategory: 'web_dashboard',
        eventAction: gtmLabel,
        omnitureData: {
          skip: true,
        },
        eventLabel: 'load',
      });
    }
    onSectionLoadUpdate(show, gtmLabel);
  }, [hotel.hotelId]);

  if (!show) {
    return null;
  }

  return (
    <Box className={classes.root} marginBottom={2.5} padding={3}>
      <Typography variant="h2" themeColor="common.white">
        {STRINGS.PERSUASION.TITLE}
      </Typography>
      <Box marginY={2}>
        <Typography themeColor="divider" className={classes.subTitle}>
          {STRINGS.PERSUASION.SUB_TITLE}
        </Typography>
      </Box>
      <Button className={classes.button} onClick={handlePersuasion}>
        {STRINGS.PERSUASION.CTA}
      </Button>
      {showOverlay && (
        <HostWebMigrationOverlay onClose={() => setShowOverlay(false)} />
      )}
    </Box>
  );
};
