import { useContext, useState } from 'react';
import { onConfirmPasswordReset } from '../LoginAPIClient';
import { useForm } from 'react-hook-form';

import {
  Button,
  ControlledInput,
  FormElement,
  Typography,
} from 'connect-web-ui';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { AuthContext } from 'utilities/Context';
import { PASSWORD_MIN_CHAR_LENGTH, SSO_ONE_LOGIN_URL } from '../login.constant';
import MessageTempleate from './MessageTempleate';
import { showMessage } from 'utilities/Utils';
import { pwdWithAtleastOneAlpaNumericAndSplCharRegex } from 'utilities/allRegex';

type ConfirmPasswordResetProps = {
  resetToken: string;
  userName: string;
  userId: string;
  isStaff: boolean;
};

const ConfirmResetPassword = ({
  userId,
  resetToken,
  userName,
  isStaff,
}: ConfirmPasswordResetProps) => {
  const isAlreadyLoggedIn = !!userName;

  const {
    langStringsData: {
      langStrings: {
        Login: { LOGIN: STRINGS },
        Common: COMMON,
      },
    },
    logOutUser,
  } = useContext(AuthContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
      oldPassword: '',
    },
  });

  const [respData, setRespData] = useState({
    success: false,
    errMsg: '',
    isAPICalled: false,
  });

  const commonPasswordValdiation = (value, fieldName = '') => {
    if (value?.length < PASSWORD_MIN_CHAR_LENGTH)
      return STRINGS.INVALID_PASS_MESS;

    if (
      fieldName !== 'oldPassword' &&
      !pwdWithAtleastOneAlpaNumericAndSplCharRegex.test(value)
    )
      return STRINGS.PASSWORD_VALIDATION;

    return false;
  };

  const validatePassowrd = fieldName => {
    const password = getValues(fieldName);

    const errorStr = commonPasswordValdiation(password, fieldName);

    if (errorStr) {
      return errorStr;
    }

    return true;
  };

  const validateConfirmPassowrd = () => {
    const [password, confirmPassword] = getValues([
      'password',
      'confirmPassword',
    ]);

    const errorStr = commonPasswordValdiation(confirmPassword);

    if (errorStr) {
      return errorStr;
    }

    if (password !== confirmPassword) return STRINGS.PASSWORD_MISMATCH;

    return true;
  };

  const handleFormSubmit = ({ password, oldPassword }) => {
    let payload = {};

    if (userName) {
      payload = {
        userName: userName,
        oldPassword: oldPassword,
        newPassword: password,
      };
    } else {
      payload = {
        userName: userId,
        password: password,
        resetToken: resetToken,
      };
    }

    onConfirmPasswordReset(payload, isAlreadyLoggedIn)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((resp: any) => {
        if (resp?.response?.success) {
          if (isAlreadyLoggedIn) {
            const isStaffUser = resp?.response?.internalRequest;
            const msg = resp?.response?.message;

            if (isStaffUser) {
              window.location.href = SSO_ONE_LOGIN_URL;
              return;
            }
            if (msg)
              showMessage({
                type: 'success',
                show: true,
                message: msg,
              });
            logOutUser();
            return;
          }
          setRespData(prev => ({
            ...prev,
            success: true,
            isAPICalled: true,
            errMsg: '',
          }));
        } else {
          const msg =
            resp?.response?.errorDetail?.displayMessage ||
            COMMON.MESSAGES.SOMETHING_WENT_WRONG;
          setRespData(prev => ({ ...prev, errMsg: msg, isAPICalled: true }));

          showMessage({
            type: 'error',
            show: true,
            message: resp?.response,
            commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
          });
        }
      })
      .catch(error => console.error('error in onConfirmPasswordReset', error));
  };

  return (
    <div>
      {respData.isAPICalled && !respData.errMsg ? (
        <div>
          <MessageTempleate isResetFlow />
        </div>
      ) : (
        <>
          {isStaff ? (
            <div className="pt-[10px] pr-4 pb-7 text-common-white">
              {STRINGS.STAFF_CHANGE_PASSWORD}
            </div>
          ) : (
            <div className="pt-5">
              <Typography
                className="!text-size-30 !mt-5 !mb-[10px] !text-[#ccc]"
                fb
              >
                {STRINGS.CHANGE_PASSWORD}
              </Typography>
              {isAlreadyLoggedIn ? (
                <div className="mt-5">
                  <div className="font-medium text-[15px]">
                    <span className="text-[#2a9fd6]">
                      {STRINGS.TIME_TO_CHANGE_PASSWORD}
                    </span>
                    <span className="text-[#ccc] pl-1">
                      {STRINGS.TIME_TO_CHANGE_PASSWORD_SUBTEXT}
                    </span>
                  </div>
                  <div className="mt-5 text-[#ff8800] font-medium">
                    <div>
                      <div className="underline">
                        {STRINGS.PASSWORD_RULE_TITLE}
                      </div>
                      <ul className="pl-[30px] mt-0">
                        {STRINGS.PASSWORD_RULES.map(rule => (
                          <li>
                            <FiberManualRecordIcon className="!text-[6px] !mr-[5px] !mb-[3px]" />
                            {rule}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mt-5">
                      <div className="underline">{COMMON.LABELS.NOTE}:</div>
                      <ul className="pl-[30px] mt-0">
                        {STRINGS.NOTES.map(note => (
                          <li>
                            <FiberManualRecordIcon className="!text-[6px] !mr-[5px] !mb-[3px]" />

                            {note}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <Typography
                  variant="subtitle1"
                  className="!mt-5 !mb-[10px] !text-[#2d73da]"
                >
                  {STRINGS.PASSWORD_TWICE_TEXT}
                </Typography>
              )}
              <div className="mt-5 pt-[10px] pr-4 pb-7 pl-7 bg-[#0C4395] w-[381px] rounded-[5px]">
                {isAlreadyLoggedIn && (
                  <FormElement
                    className="!flex-row items-baseline justify-between"
                    title={
                      <span className="text-common-white font-bold">
                        {COMMON.PLACEHOLDER.OLD_PASSWORD}:
                      </span>
                    }
                  >
                    <div className="w-[200px]">
                      <ControlledInput
                        className="mt-4 [&>*:first-child]:py-0"
                        control={control}
                        name="oldPassword"
                        isRequired={!!isAlreadyLoggedIn}
                        type="password"
                        customValidator={() => validatePassowrd('oldPassword')}
                      />{' '}
                      <div className="text-[#eb2026]">
                        {errors.oldPassword?.message}
                      </div>
                    </div>
                  </FormElement>
                )}
                <FormElement
                  className="!flex-row items-baseline justify-between"
                  title={
                    <span className="text-common-white font-bold">
                      {COMMON.PLACEHOLDER.NEW_PASSWORD}:
                    </span>
                  }
                >
                  <div className="w-[200px]">
                    <ControlledInput
                      className="mt-4 [&>*:first-child]:py-0"
                      control={control}
                      name="password"
                      isRequired
                      type="password"
                      customValidator={() => validatePassowrd('password')}
                    />{' '}
                    <div className="text-[#eb2026]">
                      {errors.password?.message}
                    </div>
                  </div>
                </FormElement>
                <FormElement
                  className="!flex-row items-baseline justify-between"
                  title={
                    <span className="text-common-white font-bold">
                      {COMMON.PLACEHOLDER.CONFIRM_PASSWORD}:
                    </span>
                  }
                >
                  <div className="w-[200px]">
                    <ControlledInput
                      className="mt-4 [&>*:first-child]:py-0"
                      control={control}
                      name="confirmPassword"
                      isRequired
                      type="password"
                      customValidator={validateConfirmPassowrd}
                    />{' '}
                    <div className="text-[#eb2026]">
                      {errors.confirmPassword?.message}
                    </div>
                  </div>
                </FormElement>
                <div className="flex justify-end">
                  <Button
                    onClick={handleSubmit(handleFormSubmit)}
                    className="mt-4"
                  >
                    {STRINGS.CHANGE_MY_PASSWORD}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ConfirmResetPassword;
