import { useEffect } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { Typography, Checkbox } from 'connect-web-ui';
import { useLangStrings } from '../../../utilities/CustomHooks';
import {
  HostMigrationCaution,
  HostMigrationCautionMobile,
} from 'assets/modules/host-migrations';

import { legacyPushToGTM } from 'utilities/gtm';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: '0px 5px 16px rgba(1, 16, 34, 0.1)',
    width: 360,
    height: '100%',
  },
}));

const cardIconList = [<HostMigrationCaution />, <HostMigrationCautionMobile />];

type HostMigrationAgreementProps = {
  showConfirm: boolean;
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  eventCategory: string;
  eventAction: string;
};

export default function HostMigrationAgreement(
  props: HostMigrationAgreementProps,
) {
  const { showConfirm, setShowConfirm, eventCategory, eventAction } = props;
  const classes = useStyles();

  const [STRINGS] = useLangStrings<'HostWebMigration'>('HostWebMigration');

  const handleAgreement = () => {
    setShowConfirm(old => {
      if (!old) {
        legacyPushToGTM({
          eventCategory,
          eventAction,
          eventLabel: 'restriction_acknowledgement',
        });
      }
      return !old;
    });
  };

  useEffect(() => {
    setShowConfirm(false);
    return () => {
      setShowConfirm(true);
    };
  }, []);

  return (
    <Box>
      <Box marginY={5}>
        <Typography variant="h1">{STRINGS.AGREEMENT.TITLE}</Typography>
        <Typography themeColor="gray.main">
          {STRINGS.AGREEMENT.CONTENT}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {cardIconList.map((cardIcon, index) => {
          const card = STRINGS.AGREEMENT.CARDS[index];
          return (
            <Grid key={index}>
              <Box className={classes.card} padding={3}>
                {cardIcon}
                <Box marginY={2}>
                  <Typography themeColor="primary.main">
                    {card.TITLE}
                  </Typography>
                </Box>
                <Typography themeColor="gray.main">{card.CONTENT}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box marginTop={5}>
        <Checkbox
          checked={showConfirm}
          onChange={handleAgreement}
          name="confirm"
        >
          <Typography themeColor="gray.main">
            {STRINGS.AGREEMENT.CONDITION}
          </Typography>
        </Checkbox>
      </Box>
    </Box>
  );
}
