import { ArrowRight, Cancel, Label } from '@mui/icons-material';
import { Modal, Tooltip, Button } from 'connect-web-ui';
import { typeIdentifier } from '../../constants/Analytics';
import { thumbsDown, thumbsUp } from 'assets/old_shared';
import { useFlagState } from '../../../../utilities/CustomHooks';
import { isNullOrUndefined, navigateTo } from 'utilities/Utils';
import { AppEvents } from 'utilities/EventBus';
import pushToGTM, { GTM_CATEGORIES } from 'utilities/gtm';

import './ActionCard.scss';

const redirect = (isDashboard, gtmLabel, redirectUrl, data) => {
  pushToGTM(
    isDashboard ? GTM_CATEGORIES.DASHBOARD : GTM_CATEGORIES.ANALYTICS,
    'content_score',
    gtmLabel,
  );
  AppEvents.emit('CSBannerData', { data, redirectUrl: redirectUrl });
  navigateTo(redirectUrl);
};

function ExtraRoomInfo(props) {
  const { title, extraRoomInfo, onClose } = props;

  return (
    <Modal
      isOpen
      header={{
        title: title,
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: 'Close',
          onClick: onClose,
        },
      }}
      customClasses={{
        children: 'extra-room-popup-content',
      }}
      onClose={onClose}
    >
      {extraRoomInfo.map(item => {
        return (
          <div className="d-flex marginB8">
            <Label fontSize="small" className="marginR8" />
            <p>{item}</p>
          </div>
        );
      })}
    </Modal>
  );
}
export function ActionCard(props) {
  const { STRINGS, data } = props;
  const { persuasion_title, message } = data?.consumers?.web ?? {};
  const { identifier, upside, penalty, all_messages } = data?.data ?? {};

  const TYPESTRINGS = typeIdentifier(STRINGS.TYPEWISE_TEXT);
  const { btnText, redirectUrl, gtmLabel } = TYPESTRINGS[identifier];
  const isGate = data?.data?.category === 'gate';

  const [isOpen, openPopup, closePopup] = useFlagState(false);

  const text_truncate = (str, length) => {
    if (str.length > length) {
      return str.substring(0, length);
    }
    return str;
  };
  function removeFirst(_, index) {
    return index > 0;
  }
  const newArr = all_messages?.filter(removeFirst);
  const popDataLength = newArr?.length;

  return (
    <div className={`cs-action-card ${isGate ? 'red' : ''}`}>
      <h5>{persuasion_title}</h5>
      <p>
        {message
          ? text_truncate(message, 105)
          : text_truncate(persuasion_title, 105)}
        {message?.length > 100 && (
          <Tooltip content={message} variant="popover">
            <span className="black-ltr sr-cursor">...</span>
          </Tooltip>
        )}
      </p>

      {!isNullOrUndefined(newArr) && (
        <>
          {popDataLength !== 0 && (
            <>
              <span className="bottom-info extra-room" onClick={openPopup}>
                {popDataLength} more task(s)
              </span>
              {isOpen && (
                <ExtraRoomInfo
                  title={persuasion_title}
                  extraRoomInfo={newArr}
                  onClose={closePopup}
                />
              )}
            </>
          )}
        </>
      )}

      {isGate ? (
        <span className="bottom-info score-change penalty">
          <img src={thumbsDown} alt="" />
          {STRINGS.SCORE_DROP} <span className="value">{penalty}%</span>
        </span>
      ) : (
        <span className="bottom-info score-change boost">
          <img src={thumbsUp} alt="" />
          {STRINGS.BOOST_SCORE} <span className="value">{upside}%</span>
        </span>
      )}

      <Button
        size="legacy"
        className="!px-[12px] !py-1"
        onClick={() => redirect(false, gtmLabel, redirectUrl, data)}
      >
        {btnText}
      </Button>
    </div>
  );
}

export function DashboardEntry(props) {
  const { STRINGS, data } = props;

  const { persuasion_title, message } =
    data?.consumers?.web || data[0]?.consumers?.web || {};
  const isGate = !!(
    data?.data?.category === 'gate' || data[0]?.data?.category === 'gate'
  );

  const { identifier } = data?.data || data[0]?.data || {};

  const TYPESTRINGS = typeIdentifier(STRINGS.TYPEWISE_TEXT);
  const { redirectUrl, gtmLabel } = TYPESTRINGS[identifier];

  return (
    <div
      className="cs-dashboard-entry"
      onClick={() => redirect(false, gtmLabel, redirectUrl, data)}
    >
      <p>{isGate ? persuasion_title : message ? message : persuasion_title}</p>
      <ArrowRight />
    </div>
  );
}

export function FloatingCSBanner(props) {
  const { STRINGS, data, onClose } = props;

  const { message } =
    data?.data[0]?.consumers?.web || data?.data?.consumers?.web || {};

  const isGate = !!(
    data?.data[0]?.data?.category === 'gate' ||
    data?.data?.data?.category === 'gate'
  );

  return (
    <div className={`cs-floating-banner ${isGate ? 'penalty' : 'boost'}`}>
      {isGate ? (
        <>
          <img src={thumbsDown} alt="" />
          {message}
          {STRINGS.TO_BOOST_SCORE}.
        </>
      ) : (
        <>
          <img src={thumbsUp} alt="" />
          {message}
          {STRINGS.TO_BOOST_SCORE}.
        </>
      )}

      <Cancel onClick={onClose} />
    </div>
  );
}
