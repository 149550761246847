import type { ISideNavProp, LeftNavItem } from './SideNav.types';
import {
  createRef,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { SideNavItem } from './SideNavItem';
import SideNavDataIdWrapper, {
  EVENT_NAME,
} from './SideNavOmnitureEventWrapper';
import {
  checkVisibility,
  classNames,
  debounce,
  prefetchImages,
} from 'utilities/Utils';
import {
  useMountSkipEffect,
  useResizeObserver,
  useWindowDimensions,
} from 'utilities/CustomHooks';
import { MoreButton } from './Components/MoreButton';
import {
  calculateRightPosition,
  getActiveMenuItem,
  handleSideNavItemClick,
  isWebkitBrowser,
} from './SideNav.utils';
import { ITEM_TYPE_ENUM } from './SideNav.constants';
import { Divider, Tooltip } from 'connect-web-ui';
import { HotelContext } from 'utilities/Context';
import { pushToOmniture } from 'utilities/gtm';

const pushedOmnitureData = {
  ingo: {},
  hotelCloud: {},
};
const pushedOmintureCount = {
  ingo: 0,
  hotelCloud: 0,
};
export default function SideNav(props: ISideNavProp) {
  const { tabs, blockedForExtranet } = props;
  const { pathname } = useLocation();
  const { setShowHelpdeskDrawer, isResellerView } =
    useContext(HotelContext) || {};
  let pushedOminture = isResellerView
    ? pushedOmintureCount.hotelCloud
    : pushedOmintureCount.ingo;
  const pushedOmnitureTabs = isResellerView
    ? pushedOmnitureData.hotelCloud
    : pushedOmnitureData.ingo;
  const listRef = useRef<HTMLDivElement>(null);
  const tabRef: Record<string, RefObject<HTMLDivElement>> = useMemo(
    () =>
      tabs.reduce((acc, tab) => {
        acc[tab.data?.title] = createRef();
        return acc;
      }, {}),
    [tabs],
  );
  const { width: currentWindowWidth } = useWindowDimensions();

  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const { height } = useResizeObserver(listRef, true);
  const isWebkit = isWebkitBrowser();
  const loadedTabsCount = Object.keys(pushedOmnitureTabs).length;

  const isScrollable = useMemo(() => {
    const listElement = listRef.current;
    if (!listElement) return false;
    const { scrollHeight, clientHeight } = listElement;
    return scrollHeight > clientHeight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, tabs.length, listRef?.current?.scrollHeight]);

  useEffect(() => {
    const imageList = tabs.map(item => item.data?.selectedIconUrl);
    prefetchImages(imageList);
  }, [tabs]);

  const { stickyTabs, labelledTabs, floatingTab } = useMemo(() => {
    return tabs.reduce(
      (
        acc: {
          stickyTabs: LeftNavItem[];
          labelledTabs: LeftNavItem[];
          floatingTab: LeftNavItem;
        },
        tab,
      ) => {
        if (tab.itemId === ITEM_TYPE_ENUM.STICKEY_ICON) {
          acc.stickyTabs.push(tab);
        } else if (tab.itemId === ITEM_TYPE_ENUM.LABELLED_ICON) {
          acc.labelledTabs.push(tab);
        } else if (tab.itemId === ITEM_TYPE_ENUM.FLOATING_ICON) {
          acc.floatingTab = tab;
        }
        return acc;
      },
      { stickyTabs: [], labelledTabs: [], floatingTab: undefined },
    );
  }, [tabs]);

  const activeMenuItem = useMemo(() => {
    return getActiveMenuItem(labelledTabs, pathname);
  }, [pathname, labelledTabs]);

  useMountSkipEffect(() => {
    if (isResellerView) {
      pushedOmnitureData.ingo = {};
      pushedOmintureCount.ingo = 0;
    } else {
      pushedOmnitureData.hotelCloud = {};
      pushedOmintureCount.hotelCloud = 0;
    }
  }, [isResellerView]);

  useEffect(() => {
    const listElement = listRef.current;
    const handleScrollHelper = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        setIsScrolledToEnd(scrollTop + clientHeight + 30 >= scrollHeight);
      }

      if (pushedOminture < tabs.length) {
        Object.keys(pushedOmnitureTabs).forEach(key => {
          if (
            pushedOmnitureTabs[key] === 'not-visible' &&
            checkVisibility(tabRef[key])
          ) {
            pushedOmnitureTabs[key] = 'fully-visible';
            pushedOminture += 1;
            pushToOmniture({
              event: 'ctaLoad',
              loadedComponents: key,
              cta: {
                name: `after_scroll | ${key}`,
                type: 'load',
                componentName: 'left_nav',
              },
            });
          }
        });
      }
    };
    const handleScroll = debounce(handleScrollHelper, 200);

    if (listElement) {
      listElement.addEventListener('scroll', handleScroll, true);
    }
    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll, true);
      }
    };
  }, [height, labelledTabs, stickyTabs, tabs.length, tabRef]);

  useEffect(() => {
    if (loadedTabsCount === tabs.length) {
      let loadedComponents = '';
      const visibleTabsCount = 0;
      Object.keys(pushedOmnitureTabs).forEach(key => {
        if (pushedOmnitureTabs[key] === 'fully-visible') {
          pushedOminture += 1;
          loadedComponents += (loadedComponents === '' ? '' : ' | ') + key;
        }
      });
      pushedOminture = visibleTabsCount;
      pushToOmniture({
        event: 'ctaLoad',
        loadedComponents,
        cta: {
          name: 'left_nav_first_load',
          type: 'load',
          componentName: 'left_nav',
        },
      });
    }
  }, [loadedTabsCount, tabs.length]);

  return (
    <>
      <div
        className={classNames(
          'borderRight-gray-cb shadow-[6px_6px_6px_0px_rgba(0,0,0,0.05)] bg-color-white z-[1] overflow-x-hidden w-[88px]',
          blockedForExtranet
            ? 'opacity-50 pointer-events-none cursor-not-allowed'
            : '',
        )}
      >
        <div
          className={`h-[calc(100vh-72px)] bottom-0 left-0 overflow-y-auto overflow-x-hidden relative ${
            !isWebkit ? 'scrollbar-thin' : 'connect-scrollbar-thin'
          } !outline-none mr-[1px] ${isScrollable ? 'pb-2' : ''}`}
          ref={listRef}
          data-test-id="side-nav-container"
        >
          <div className="flex items-center flex-col gap-[6px] pt-2">
            {labelledTabs.map((tab: LeftNavItem) => (
              <SideNavDataIdWrapper
                eventName={
                  tab?.items?.length ? EVENT_NAME.mouseEnter : EVENT_NAME.click
                }
                trackingConfig={
                  tab?.items?.length
                    ? tab?.trackingConfig
                    : tab?.cta?.trackingConfig
                }
                addDataId
                key={tab.data?.title + isResellerView}
              >
                <SideNavItem
                  ref={tabRef[tab.data?.title]}
                  tabItem={tab}
                  isActive={activeMenuItem === tab.data?.title}
                  pushedOmnitureTabs={pushedOmnitureTabs}
                />
              </SideNavDataIdWrapper>
            ))}
            {!!stickyTabs.length && <Divider className="w-[82px]" />}
            {stickyTabs.map((tab: LeftNavItem) => (
              <SideNavDataIdWrapper
                trackingConfig={tab.trackingConfig}
                addDataId
                key={tab.data?.title + isResellerView}
              >
                <SideNavItem
                  ref={tabRef[tab.data?.title]}
                  tabItem={tab}
                  isActive={activeMenuItem === tab.data?.title}
                  pushedOmnitureTabs={pushedOmnitureTabs}
                />
              </SideNavDataIdWrapper>
            ))}
          </div>
          {isScrollable && !isScrolledToEnd && <MoreButton listRef={listRef} />}
        </div>
      </div>
      {floatingTab ? (
        <Tooltip
          content={floatingTab?.data?.toast}
          placement="top-end"
          bgColor="common.black"
          customClasses={{
            popper: '!mb-5',
          }}
        >
          <div
            className="fixed bottom-4 select-none z-50"
            style={{
              right: calculateRightPosition(currentWindowWidth),
            }}
          >
            <img
              src={floatingTab?.data?.iconUrl}
              alt="Float Up"
              className="cursor-pointer"
              onClick={() =>
                handleSideNavItemClick(floatingTab.cta, setShowHelpdeskDrawer)
              }
            />
          </div>
        </Tooltip>
      ) : undefined}
    </>
  );
}
