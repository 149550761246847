import React, { useEffect, useRef, useState } from 'react';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material';
import { useMountSkipEffect } from 'utilities/CustomHooks';
import { UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { IconButton } from 'connect-web-ui';

import './Table.scss';

type TablePaginationActionsProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => void;
};

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const firstPageClick = event => {
    onPageChange(event, 0);
  };

  const prevPageClick = event => {
    onPageChange(event, page - 1);
  };

  const nextPageClick = event => {
    onPageChange(event, page + 1);
  };

  const lastPageClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const disablePrev = page === 0;
  const disableNext = page >= Math.ceil(count / rowsPerPage) - 1;

  return (
    <div className="mui-pagination-buttons">
      <IconButton
        IconComp={FirstPage}
        disabled={disablePrev}
        onClick={firstPageClick}
        size="large"
      />
      <IconButton
        IconComp={KeyboardArrowLeft}
        disabled={disablePrev}
        onClick={prevPageClick}
        size="large"
      />
      <IconButton
        IconComp={KeyboardArrowRight}
        disabled={disableNext}
        onClick={nextPageClick}
        size="large"
      />
      <IconButton
        IconComp={LastPage}
        disabled={disableNext}
        onClick={lastPageClick}
        size="large"
      />
    </div>
  );
}

type UsePaginationOptions = {
  pageSize: number;
  queryOption: (
    pageNum: number,
  ) => UseQueryOptions<{ totalCount: number; data }>;
  otherFetchDeps: unknown[];
  resetPageDeps: unknown[];
};

export function useTablePaginationWithSuspense(options: UsePaginationOptions) {
  const { pageSize, queryOption, resetPageDeps = [] } = options;
  const [pageNum, setPageNum] = useState(0);
  const [data, setData] = useState([]);
  const isFirstLoad = useRef(false);
  const totalCount = useRef(0);
  const handleDeleteRow = index => {
    setData(pre => {
      const toReturn = [...pre];
      toReturn.splice(index, 1);
      return toReturn;
    });
  };

  const { data: _data } = useSuspenseQuery(queryOption(pageNum + 1));
  useEffect(() => {
    totalCount.current = _data.totalCount;
    isFirstLoad.current = true;
    setData(_data.data);
  }, [_data]);

  useMountSkipEffect(() => {
    setPageNum(0);
  }, [...resetPageDeps]);

  return {
    enablePagination: totalCount.current > pageSize,
    pageNum,
    setPageNum,
    totalCount: totalCount.current,
    pageSize,
    data,
    setData,
    handleDeleteRow,
    isFirstLoad: isFirstLoad.current,
  };
}

export function useTablePagination(
  pageSize: number,
  fetchData: (pageNumber: number) => Promise<{ totalCount: number; data }>,
  otherFetchDeps = [],
  resetPageDeps = [],
) {
  const [pageNum, setPageNum] = useState(0);
  const [data, setData] = useState([]);
  const isFirstLoad = useRef(false);
  const totalCount = useRef(0);
  const handleDeleteRow = index => {
    setData(pre => {
      const toReturn = [...pre];
      toReturn.splice(index, 1);
      return toReturn;
    });
  };

  useEffect(() => {
    //pageNum is zero indexed
    fetchData(pageNum + 1)
      .then(res => {
        totalCount.current = res.totalCount;
        isFirstLoad.current = true;
        setData(res.data);
      })
      .catch(error => {
        console.error('fetchData', error);
      });
  }, [pageNum, ...otherFetchDeps]);

  useMountSkipEffect(() => {
    setPageNum(0);
  }, [...resetPageDeps]);

  return {
    enablePagination: totalCount.current > pageSize,
    pageNum,
    setPageNum,
    totalCount: totalCount.current,
    pageSize,
    data,
    setData,
    handleDeleteRow,
    isFirstLoad: isFirstLoad.current,
  };
}
