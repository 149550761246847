import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& div[class*="MuiPaper-root"]': {
      background: 'transparent',
      padding: 0,
      position: 'relative',
      boxShadow: 'none',

      '& span[class*="MuiStepLabel-root"]': {
        alignItems: 'flex-start',
        padding: 0,
      },

      '& div[class*="MuiStepContent-root"]': {
        marginTop: theme.spacing(0),
        paddingRight: theme.spacing(0),
        borderWidth: '2px',
        borderColor: theme.palette.backgrounds.divider,
      },

      '& div[class*="MuiStepConnector-root"]': {
        height: theme.spacing(2),
        ' & > span': {
          minHeight: theme.spacing(5),
          borderColor: 'transparent',
        },
      },
      '& span[class*="MuiStepLabel-labelContainer"]': {
        overflow: 'auto',
      },
      '& span[class*="MuiStepLabel-label Mui-completed"]': {
        '&>*': {
          border: '1px solid #D2D1D1',
        },
      },
      '& span[class*="MuiStepLabel-label Mui-disabled"]': {
        '&>*': {
          border: '1px solid #D2D1D1',
        },
      },
    },
  },
  stepLabel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    marginLeft: '9px',
    zIndex: 1,
    flexDirection: 'column',
  },
  stepContent: (props: { isLast: boolean }) => ({
    position: 'relative',
    paddingTop: '75px',
    marginTop: '-75px',
    background: theme.palette.common.white,
    borderRadius: '4px',
    marginLeft: props.isLast ? '12px' : '10px',
  }),
  stepContentAnimate: {
    animation: `$animation 1000ms ${theme.transitions.easing.easeIn}`,
    animationFillMode: 'forwards',
  },
  '@keyframes animation': {
    '0%': {
      boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0)',
    },
    '100%': {
      boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.25)',
    },
  },
  _step: {
    padding: theme.spacing(2),
    borderTop: '1px solid #D2D1D1',
  },
  stepFooter: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #D2D1D1',
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  disableIcon: {
    backgroundColor: theme.palette.backgrounds.divider,
    height: '28px',
    width: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  activeIcon: {
    backgroundColor: theme.palette.backgrounds.primary,
    height: '28px',
    width: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #3543BF',
    borderRadius: '50%',
  },
  headerConnector: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginLeft: '-1px',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: '88px',
    right: 0,
    height: '72px',
    background: '#FFFFFF',
    boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 12,
  },
}));
