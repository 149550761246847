/**
 * This was created to maintain the promise based axios
 * with defaults.
 */
// import { routes } from 'app/routes';
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { LogoutConfirmEvent } from 'utilities/EventBus';
import {
  getAPIToken,
  getParsedErrorMessageFromGraphql,
  isNullOrUndefined,
  showGlobalLoader,
  showMessage,
  getErrorMessage,
} from 'utilities/Utils';
import { pushToOmniture } from 'utilities/gtm';

declare module 'axios' {
  interface AxiosRequestConfig {
    useLoader?: boolean;
  }
}

const {
  VITE_CONST_PROD_ORIGIN: PROD_ORIGIN,
  VITE_EXTRANET_API_ORIGIN: extranetOrigin,
  VITE_ENIGMA_API_ORIGIN: enigmaBase,
} = import.meta.env;

// External Service URL
export const isProd = window.location.origin === PROD_ORIGIN;
export const isLocalProd = extranetOrigin === PROD_ORIGIN;

const enigmaGraphBase = `${enigmaBase}/graphql`;
const instance = axios.create();
const CancelToken = axios.CancelToken;

type ErrorType = Partial<{
  error: string;
  message: string;
  msg: string;
  errors: Array<{ message: string }>;
}>;

function showError(data: string | ErrorType | undefined) {
  const error = getErrorMessage(data);
  if (error) {
    showMessage({
      show: true,
      message: error,
      type: 'error',
    });
  }
}

function setCommonHeaders(csrf_token = null, axiosInstance = instance) {
  if (csrf_token) {
    axiosInstance.defaults.headers.common['X-CSRFToken'] = csrf_token;
  }
  axiosInstance.defaults.headers.common['meta-data'] = JSON.stringify({
    source: 'extranet',
  });
  axiosInstance.defaults.headers.common['meta-data-source'] = 'ingo_web';
  axiosInstance.defaults.headers.common['meta-data-platform'] = 'web';
  axiosInstance.defaults.headers.common['meta-data-brand'] = 'INGO';
  axiosInstance.defaults.headers.common.source = 'ingo_web';
  axiosInstance.defaults.headers.common.platform = 'Desktop';
  axiosInstance.defaults.headers.common.language = 'en';
  axiosInstance.defaults.headers.common.country = 'in';
}

const sendApiOrQueryErrorOmnitureEvent = ({
  error,
  omnitureData = { cta: {}, loadedComponents: '' },
}) => {
  let lastOmnitureData;
  for (let i = window.adobeDataLayer?.length - 1; i >= 0; i--) {
    if (window.adobeDataLayer[i].event !== 'ctaLoad') {
      lastOmnitureData = { ...window.adobeDataLayer[i] };
      break;
    }
  }
  lastOmnitureData = lastOmnitureData ??
    window.adobeDataLayer?.[window.adobeDataLayer?.length - 1] ?? { cta: {} };

  const statusCode = error.status || error?.response?.status;

  const { cta = {}, ...otherData } = omnitureData;

  const loadedComponents =
    statusCode >= 400 && statusCode < 500
      ? 'Frontend Error'
      : otherData?.loadedComponents ?? 'Backend error';
  const omnitureDataRes = {
    ...lastOmnitureData,
    event: 'ctaLoad',
    cta: {
      ...lastOmnitureData?.cta,
      type: 'load',
      name: getErrorMessage(error?.response?.data || error), // error message
      misc_id_1: lastOmnitureData?.cta?.name ?? '', // cta name
      misc_id_2: statusCode, // status code,
      ...cta,
    },
    ...otherData,
  };
  if (omnitureDataRes?.page?.pageInfo) {
    omnitureDataRes.page.pageInfo.loadedComponents = loadedComponents;
  } else {
    omnitureDataRes.loadedComponents = loadedComponents;
  }
  pushToOmniture(omnitureDataRes);
};

function wrappedAxiosCall(
  { method, url, data = null, ...rest }: AxiosRequestConfig,
  omnitureData = null,
) {
  omnitureData = isNullOrUndefined(omnitureData)
    ? { cta: {} }
    : { ...omnitureData };
  return new Promise((resolve, reject) => {
    omnitureData.cta.misc_id_3 = url;
    return instance({
      method,
      url,
      data,
      useLoader: true,
      ...rest,
    })
      .then(({ data: respData }) => {
        // Resolve data directly for older api's
        if (isNullOrUndefined(respData.success)) {
          resolve(respData);
        }
        if (respData.success) {
          resolve(respData.data);
        } else {
          sendApiOrQueryErrorOmnitureEvent({ error: respData, omnitureData });
          showError(respData);
          reject(
            new Error(respData?.error || respData?.message || respData?.msg),
          );
        }
      })
      .catch(err => {
        sendApiOrQueryErrorOmnitureEvent({ error: err, omnitureData });
        showError(err?.response?.data || err);
        reject(err);
      });
  });
}
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

//********** API Wrapper ****************
export function apiWrapper<T = unknown>(
  promise: AxiosPromise,
  omnitureData = null,
  displayError = true,
) {
  omnitureData = isNullOrUndefined(omnitureData)
    ? { cta: {} }
    : { ...omnitureData };
  return new Promise((resolve, reject) => {
    promise
      .then(resp => {
        const { data, request } = resp;
        if (data) {
          if (isPlainObject(data)) {
            data.status = resp.status;
          }
          resolve(data);
        } else {
          omnitureData.cta.misc_id_3 = request?.responseURL;
          sendApiOrQueryErrorOmnitureEvent({ error: resp, omnitureData });
          if (displayError) showError(data);
          reject(data);
        }
      })
      .catch(err => {
        omnitureData.cta.misc_id_3 = err?.request?.responseURL;
        sendApiOrQueryErrorOmnitureEvent({ error: err, omnitureData });
        if (displayError) showError(err?.response?.data || err);
        reject(err);
      });
  }) as Promise<T>;
}

// This will be used for APIs which will be sending binary data (like excel, pdf etc)
export function binaryApiWrapper(promise: AxiosPromise, omnitureData = null) {
  omnitureData = isNullOrUndefined(omnitureData)
    ? { cta: {} }
    : { ...omnitureData };
  return new Promise((resolve, reject) => {
    promise
      .then(resp => {
        const { data, request } = resp;
        if (data) {
          resolve(resp);
        } else {
          omnitureData.cta.misc_id_3 = request?.responseURL;
          sendApiOrQueryErrorOmnitureEvent({ error: resp, omnitureData });
          showError(data);
          reject(resp);
        }
      })
      .catch(err => {
        omnitureData.cta.misc_id_3 = err?.request?.responseURL;
        sendApiOrQueryErrorOmnitureEvent({ error: err, omnitureData });
        showError(err?.response?.data || err);
        reject(err);
      });
  });
}

function showGraphQlError(error) {
  showError({ msg: getParsedErrorMessageFromGraphql(error) });
}

const handlePermissionIssue = () => {
  showMessage({
    show: true,
    message:
      'You are currently forbidden to perform this action, please contact your Hotel Administrator for the relevant permissions.',
    type: 'error',
    persistUntilClear: true,
  });
};

const reqInterceptor = config => {
  const lang = localStorage.getItem('ingo_user_lang') || 'En';

  if (config.url.indexOf(`strings/${lang}`) === -1) {
    config.headers.Authorization = `Token ${getAPIToken()}`;
  }

  if (config.useLoader) {
    showGlobalLoader(true);
  }
  if (window.location.href.includes('/onboarding')) {
    config.headers.onboarding = 'true';
  }

  return config;
};

const handleSuccess = res => {
  if (res.config.useLoader) {
    showGlobalLoader(false);
  }
  return res;
};
const handleFailure = err => {
  if (err.config?.useLoader) {
    showGlobalLoader(false);
  }
  if (err.response?.status === 403) {
    handlePermissionIssue();
  }
  if (err.response?.status === 401) {
    LogoutConfirmEvent.emit('logoutUser');
  }
  return Promise.reject(err);
};

instance.interceptors.request.use(reqInterceptor);
instance.interceptors.response.use(handleSuccess, handleFailure);

const deleteAuthTransform = (data, headers) => {
  delete headers.common['X-CSRFToken'];
  delete headers.common.Authorization;
  delete headers.common['meta-data'];
  return data;
};

export default instance;
export {
  deleteAuthTransform,
  CancelToken,
  setCommonHeaders,
  showError,
  wrappedAxiosCall,
  enigmaBase,
  enigmaGraphBase,
  showGraphQlError,
  sendApiOrQueryErrorOmnitureEvent,
  reqInterceptor,
  handleSuccess,
  handleFailure,
};
