import { useState, useEffect } from 'react';
import { Modal, Radio, Typography } from 'connect-web-ui';
import { Divider } from '@mui/material';
import type { IntroVideoModalPropsType } from './IntroVideoModal.types';

const options = [
  {
    label: 'English',
    value: 'english',
  },
  {
    label: 'हिन्दी',
    value: 'hindi',
  },
  {
    label: 'ಕನ್ನಡ',
    value: 'kanada',
  },
  {
    label: 'தமிழ்',
    value: 'tamil',
  },
];

const IntroVideoModal = (props: IntroVideoModalPropsType) => {
  const {
    langVideoUrlMap = { english: '', hindi: '', kannada: '', tamil: '' },
    positiveText = 'Continue',
    qa = {},
    handleClose,
    handleContinue,
    title = '',
    subtitle = '',
    children,
    disableBackdropClick = true,
    showHeader = true,
    showTitle = false,
    onLoadCb,
    className = 'w-[704px]',
    disablePositiveBtn = false,
    dividerClass = 'mx-6',
  } = props;

  const showLangs = Object.keys(langVideoUrlMap);
  const [language, setLanguage] = useState('english');
  const renderOptions = options.filter(lang => showLangs.includes(lang.value));

  useEffect(() => {
    onLoadCb?.();
  }, []);

  return (
    <Modal
      isOpen
      size="custom"
      onClose={handleClose}
      allowBackDropClick={!disableBackdropClick}
      footer={{
        primaryBtn: {
          text: positiveText,
          onClick: handleContinue,
          disabled: disablePositiveBtn,
        },
        hideFooterDivider: true,
      }}
      customClasses={{
        footer: {
          root: '!justify-center !py-4',
        },
        children: '!pt-0',
        header: {
          root: '!px-5',
        },
      }}
      header={
        showHeader
          ? {
            title: (
                <div className="pt-1">
                  {showLangs.length > 1 && (
                    <Radio
                      value={language}
                      options={renderOptions}
                      onChange={setLanguage}
                      inline
                    />
                  )}
                </div>
            ),
            hideHeaderDivider: true,
          }
          : null
      }
    >
      <div className="flex flex-col items-center px-2 flex-1">
        {showTitle && (
          <div className="mb-4 flex flex-col w-full">
            <Typography variant="h2">{title}</Typography>
            <Typography variant="subHeadingRegular">{subtitle}</Typography>
          </div>
        )}
        <div className="flex w-full h-[292px]">
          <iframe
            width="100%"
            height="290"
            allowFullScreen
            src={langVideoUrlMap[language]}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div className={`mt-4 flex ${className}`}>
          <div className="w-1/2">
            <Typography variant="h3">{qa.Q1}</Typography>
            <Typography themeColor="text.primary">
              <div
                dangerouslySetInnerHTML={{ __html: qa.ANS1 }}
                className="mb-0"
              />
            </Typography>
          </div>
          <Divider orientation="vertical" className={dividerClass} flexItem />
          <div className="w-1/2">
            <Typography variant="h3">{qa.Q2}</Typography>
            <Typography themeColor="text.primary">
              <div
                dangerouslySetInnerHTML={{ __html: qa.ANS2 }}
                className="mb-0"
              />
            </Typography>
          </div>
          {qa.Q3 && (
            <Divider orientation="vertical" className={dividerClass} flexItem />
          )}
          {qa.Q3 && (
            <div className="w-1/2">
              <Typography variant="h3">{qa.Q3}</Typography>
              <Typography themeColor="text.primary">
                <div
                  dangerouslySetInnerHTML={{ __html: qa.ANS3 }}
                  className="mb-0"
                />
              </Typography>
            </div>
          )}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default IntroVideoModal;
