import { routes } from 'app/routes';
import { Typography } from 'connect-web-ui';
import { useContext } from 'react';
import { AuthContext } from 'utilities/Context';
import { navigateTo } from 'utilities/Utils';

type TemplateProps = {
  errorMessage?: string;
  isResetFlow?: boolean;
};

const MessageTempleate = (props: TemplateProps) => {
  const { errorMessage, isResetFlow = false } = props;
  const {
    langStringsData: {
      langStrings: {
        Login: { LOGIN: STRINGS },
      },
    },
    logOutUser,
    isLoggedIn,
  } = useContext(AuthContext);

  let msg1 = STRINGS.ACTIVATION_SUCCESS;
  let msg2 = STRINGS.REGISTER_PROPERTY;
  const msg3 = STRINGS.LOGIN_HERE;

  if (isResetFlow) {
    msg1 = STRINGS.PASSWORD_RESET_SUCCESS;
    msg2 = STRINGS.POST_SUCCESS_LOGIN;
  }

  return (
    <div className="pt-5">
      {errorMessage ? (
        <Typography variant="body1" className="!text-[#ccc] !mt-[10px]">
          {errorMessage}
        </Typography>
      ) : (
        <>
          <Typography variant="body1" className="!text-[#ccc] !mt-[10px]">
            {msg1}
          </Typography>
          <Typography variant="body1" className="!text-[#ccc] !mt-[10px]">
            {msg2}
          </Typography>
          <Typography variant="body1" className="!text-[#ccc] !mt-[10px]">
            {msg3}{' '}
            <span
              className="underline text-[#2D67B2] cursor-pointer"
              onClick={() =>
                isLoggedIn ? logOutUser() : navigateTo(routes.login.base)
              }
            >
              {STRINGS.HERE}
            </span>
          </Typography>
        </>
      )}

      <Typography
        variant="body1"
        className="!text-size-20 !my-[10px] !text-[#F26722] !font-bold"
      >
        {STRINGS.INGO_TEAM}
      </Typography>
    </div>
  );
};

export default MessageTempleate;
