import {
  createRef,
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
} from '@mui/material';
import { Error, GreenCheck } from 'assets/latest_core';
import { Button, Tooltip, Typography } from 'connect-web-ui';
import { useResizeObserver } from 'utilities/CustomHooks';
import { StepperProps } from './Stepper.types';
import { useStyles } from './Stepper.styles';
import { NewContainer } from 'components/common';

const StepperComponent = (
  props: StepperProps,
  ref?: React.Ref<HTMLDivElement>,
) => {
  const {
    title,
    subtitle,
    icon,
    steps,
    onSubmit,
    activeStep,
    setActiveStep,
    negativeText = 'cancel',
    positiveText = 'Save',
    onCancel,
    onNextClick,
    NextButtonText = 'Next',
    enableSave,
    guidePopup,
    errorMap = [],
    children,
    isEditMode = false,
    editButtonText = 'Edit',
    errorMessage = null,
    onClickEdit,
    className,
    isLayoutDynamic = false,
    disableCancel = false,
    showSkipCta = false,
    handleSkipClick = () => true,
    lastCompletedStep = 0,
    enableFooterBtn = null,
    preEdit = null,
    hideErrorMsg = false,
    positiveTextToolTip = '',
  } = props;

  const [contentHeight, setContentHeight] = useState(steps.map(() => 0));
  const [completedStep, setCompletedStep] = useState(activeStep + 1);

  const isLast = activeStep === steps.length - 1;

  const borderColor = useRef({
    top: steps.map(() => '#D2D1D1'),
    bottom: steps.map(() => '#D2D1D1'),
  });

  const classes = useStyles({ isLast });

  const stepperRefs = useMemo(() => {
    return steps.map(() => {
      return {
        label: createRef<HTMLDivElement>(),
        content: createRef<HTMLDivElement>(),
      };
    });
  }, [steps]);

  const StepIcon = _props => {
    const { active, error, icon: _icon, completed } = _props;
    const _classes = useStyles({ isLast });

    let iconType = 4;
    if (active) {
      iconType = 1;
    } else if (completed) {
      iconType = 2;
    }
    if (error) {
      iconType = 3;
    }
    if (steps[_icon - 1]?.iconLink) {
      iconType = 5;
    }
    const stepIcon = () => {
      switch (iconType) {
        case 1:
          return (
            <div className={_classes.activeIcon}>
              <Typography
                variant="subHeadingMedium"
                themeColor="primary.main"
                fb
              >
                {_icon}
              </Typography>
            </div>
          );

        case 2:
          return <GreenCheck height="28px" width="28px" />;
        case 3:
          return <Error height="28px" width="28px" />;
        case 4:
          return (
            <div className={_classes.disableIcon}>
              <Typography variant="subHeadingMedium" themeColor="gray.main" fb>
                {_icon}
              </Typography>
            </div>
          );

        case 5:
          return (
            <img
              src={steps[_icon - 1].iconLink}
              alt={_icon}
              className="h-7 w-7 rounded-full select-none z-[1] bg-color-white"
            />
          );

        default:
          return null;
      }
    };

    return (
      <div className={_classes.headerConnector}>
        {_icon !== 1 && (
          <div
            style={{
              position: 'absolute',
              height: '32px',
              borderLeft: `2px solid ${borderColor.current.top[_icon - 1]}`,
              top: '-32px',
            }}
          ></div>
        )}
        {stepIcon()}
        {steps.length !== _icon && (
          <div
            style={{
              position: 'absolute',
              height: `${contentHeight[_icon - 1]}px`,
              borderLeft: `2px solid ${borderColor.current.bottom[_icon - 1]}`,
              zIndex: -1,
              top: '28px',
            }}
          ></div>
        )}
      </div>
    );
  };

  const handleNext = e => {
    e.stopPropagation();
    const nextStep = activeStep + 1;
    if (onNextClick) {
      const res = onNextClick(nextStep);
      Promise.resolve(res)
        .then(returnVal => {
          if (returnVal) {
            setActiveStep(nextStep);
            stepperRefs[nextStep]?.label.current?.scrollIntoView(false);
            if (completedStep < nextStep) setCompletedStep(nextStep);
          }
        })
        .catch(error => {
          console.error('Error in onNextClick', error);
        });
    } else {
      setActiveStep(nextStep);
    }
  };

  const onEditClick = (isError, index) => {
    if (preEdit) {
      preEdit(index);
    } else {
      if (!isError) setActiveStep(index);
      onClickEdit?.(index);
    }
  };

  const { height = 0 } = useResizeObserver(
    stepperRefs[activeStep]?.content,
    true,
  );

  useLayoutEffect(() => {
    setContentHeight(
      stepperRefs.map(
        stepperRef => stepperRef.label.current?.clientHeight ?? 0,
      ),
    );
  }, [activeStep, height, stepperRefs]);

  const getStepperErrorMessage = (errorCount: number) => {
    const errorText = `${errorCount} __placeholder__ found. Please correct it to proceed further.`;
    if (errorCount > 1) {
      return errorText.replace('__placeholder__', 'errors');
    }
    return errorText.replace('__placeholder__', 'error');
  };
  const toMainReturn = (
    <div>
      {!!title && (
        <div className={`flex items-center mb-4 ${icon && 'ml-1'}`}>
          {icon}
          <Typography variant="h1" className={icon && 'ml-4'}>
            {title}
            {subtitle && <Typography variant="h3">{subtitle}</Typography>}
          </Typography>
        </div>
      )}

      <Paper>
        <MuiStepper activeStep={activeStep} orientation="vertical" ref={ref}>
          {steps.map((step, index) => {
            const isActive = activeStep === index;
            const isDisabled = step?.enableNext ? !step.enableNext() : false;
            if (step.sideLineColor) {
              borderColor.current.bottom[index] = step.sideLineColor;
            } else if (errorMap?.[index] > 0) {
              borderColor.current.bottom[index] = '#EB2026';
            } else if (!isActive && index < completedStep) {
              borderColor.current.bottom[index] = '#2E7D32';
            } else if (isActive) {
              borderColor.current.bottom[index] = '#3543BF';
            } else {
              borderColor.current.bottom[index] = '#D2D1D1';
            }
            borderColor.current.top[index + 1] =
              borderColor.current.bottom[index];

            const isError = !!errorMap.reduce((acc, cur) => acc + cur, 0);

            return (
              <Step key={step.title.toString()}>
                <StepLabel
                  StepIconComponent={StepIcon}
                  StepIconProps={{
                    error: errorMap?.[index] > 0,
                    completed: index < completedStep,
                  }}
                  ref={stepperRefs[index]?.label}
                >
                  <div className={classes.stepLabel}>
                    <div className="flex justify-between w-full">
                      <Typography
                        variant={isActive ? 'h3' : 'subHeadingMedium'}
                      >
                        {step.title}
                      </Typography>
                      {!isActive &&
                        (index < lastCompletedStep ||
                          index < completedStep) && (
                          <Button
                            variant="text"
                            onClick={() => onEditClick(isError, index)}
                          >
                            {editButtonText}
                          </Button>
                      )}
                    </div>
                    {isActive && (
                      <Typography
                        variant="body1"
                        themeColor="text.secondary"
                        className={`${step.subtitle ? 'h-auto' : 'h-[14px]'}`}
                      >
                        {step.subtitle}
                      </Typography>
                    )}

                    {!isActive &&
                      (index < lastCompletedStep || index < completedStep) && (
                        <div className="w-full">{step.summary}</div>
                    )}
                  </div>
                </StepLabel>
                <StepContent
                  style={{
                    borderColor: borderColor.current.bottom[index],
                  }}
                  ref={stepperRefs[index]?.content}
                >
                  <div
                    className={`${classes.stepContent} ${classes.stepContentAnimate}`}
                  >
                    <div className={classes._step}>{step.content}</div>
                    {(!isLast || isEditMode) && (
                      <div className={classes.stepFooter}>
                        <div className="flex flex-row">
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            disabled={isDisabled}
                            data-test-id="next-cta"
                          >
                            {step.buttonName ?? NextButtonText}
                          </Button>
                          {step.secondaryCTA}
                          {showSkipCta && (
                            <Button
                              variant="text"
                              className="ml-8"
                              data-test-id="skip-cta"
                              disabled={!!step.disableSkip}
                              onClick={() => {
                                const moveToNextStep = handleSkipClick(
                                  index + 1,
                                );
                                if (moveToNextStep) {
                                  setActiveStep(index + 1);
                                  stepperRefs[
                                    index + 1
                                  ]?.label.current?.scrollIntoView(false);
                                  setCompletedStep(index + 1);
                                }
                              }}
                            >
                              Skip
                            </Button>
                          )}
                        </div>
                        {!hideErrorMsg && errorMap?.[index] > 0 && (
                          <Typography themeColor="error.main" className="ml-2">
                            {getStepperErrorMessage(errorMap?.[index])}
                          </Typography>
                        )}
                      </div>
                    )}
                  </div>
                  {isLast && children}
                </StepContent>
              </Step>
            );
          })}
        </MuiStepper>
      </Paper>
    </div>
  );

  let toSaidReturn;
  if (steps[activeStep]?.sideContent || !isLayoutDynamic) {
    toSaidReturn = (
      <div>
        {guidePopup}
        <div key={activeStep}>{steps[activeStep]?.sideContent}</div>
      </div>
    );
  }

  return (
    <>
      {toSaidReturn ? (
        <NewContainer
          className={`${classes.root} [&>div]:mb-24 ${className}`}
          key={activeStep}
        >
          {toMainReturn}
          {toSaidReturn}
        </NewContainer>
      ) : (
        <NewContainer
          className={`${classes.root} [&>div]:!min-w-0 [&>div]:mb-24 ${className}`}
          key={activeStep}
        >
          {toMainReturn}
        </NewContainer>
      )}

      <div className={classes.footer}>
        <NewContainer widthSrContainer twoColLayout className="!py-0">
          <div className="h-[72px] flex justify-between items-center">
            <Button
              variant="text"
              onClick={onCancel}
              disabled={disableCancel}
              data-test-id="stepper-footer-negative-button"
            >
              {negativeText}
            </Button>
            <div className="flex justify-between items-center">
              {errorMessage ? (
                <Typography themeColor="error.main" className="mr-2">
                  {errorMessage}
                </Typography>
              ) : (
                errorMap?.[steps.length - 1] > 0 && (
                  <Typography themeColor="error.main" className="mr-2">
                    {getStepperErrorMessage(errorMap?.[steps.length - 1])}
                  </Typography>
                )
              )}

              <Tooltip content={positiveTextToolTip}>
                <span>
                  <Button
                    variant="contained"
                    disabled={
                      enableFooterBtn !== null
                        ? !enableFooterBtn
                        : activeStep !== steps.length - 1 || enableSave
                    }
                    onClick={onSubmit}
                    data-test-id="stepper-footer-submit"
                  >
                    {positiveText}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </NewContainer>
      </div>
    </>
  );
};

export const Stepper = forwardRef(StepperComponent);
