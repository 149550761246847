import { useEffect, useState } from 'react';
import { Toast } from 'connect-web-ui';
import { AppEvents } from '../utilities/EventBus';

type State = {
  show: boolean;
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
  persistUntilClear: boolean;
  title?: string;
};

const initialState: State = {
  show: false,
  message: '',
  type: 'success',
  persistUntilClear: false,
  title: '',
};

export default function FloatingMessage() {
  const [{ show, message, type, persistUntilClear, title }, showMessage] =
    useState(initialState);

  useEffect(() => {
    const showMessageHandler = data => {
      showMessage(oldData => {
        if (oldData.persistUntilClear) {
          return oldData;
        }
        return data;
      });
    };

    AppEvents.on('showToast', showMessageHandler);
    return () => {
      AppEvents.off('showToast', showMessageHandler);
    };
  }, []);

  const closeHandler = () => showMessage(initialState);

  if (!show) {
    return null;
  }

  return (
    <Toast
      type={type}
      onClose={closeHandler}
      open
      autoHide={!persistUntilClear}
      title={title}
      message={message}
    />
  );
}
