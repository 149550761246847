import Button from '@mui/material/Button';
import { ControlledInput, FormElement, Typography } from 'connect-web-ui';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { emailRegex } from 'utilities/allRegex';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import { activateUserAccount, initiatePasswordReset } from '../LoginAPIClient';
import {
  clearQueryParams,
  getParsedErrorMessageFromAPI,
  navigateTo,
  showMessage,
} from 'utilities/Utils';

import ConfirmResetPassword from './ConfirmResetPassword';
import MessageTempleate from './MessageTempleate';
import { AuthContext } from 'utilities/Context';
import { SSO_ONE_LOGIN_URL } from '../login.constant';
import { pushToOmniture } from 'utilities/gtm';
import { pushToCtaOmnitureCommon } from '../utils';
import { routes } from 'app/routes';

const InitiatePasswordReset = () => {
  const {
    langStringsData: {
      langStrings: {
        Login: { LOGIN: STRINGS },
        Common: { LABELS: COMMON, MESSAGES },
      },
    },
  } = useContext(AuthContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: { email: '' },
  });

  const onPasswordReset = value => {
    initiatePasswordReset(value)
      .then(resp => {
        // @ts-ignore
        const data = resp?.response;

        const isStaff = data?.internalRequest;

        if (isStaff) {
          window.location.href = SSO_ONE_LOGIN_URL;
          return;
        }
        pushToCtaOmnitureCommon({
          ctaName: 'Reset_Password',
          componentName: 'Forgot_Password|Reset_Password',
          pageName: 'password_reset',
        });
        showMessage({
          show: true,
          message: STRINGS.REST_LINK_TO_EMAIL,
        });
        setValue('email', '');
      })
      .catch(error => {
        pushToCtaOmnitureCommon({
          ctaName: 'Reset_Password',
          componentName: 'Forgot_Password|Reset_Password',
          pageName: 'password_reset',
          errMsg:
            getParsedErrorMessageFromAPI(error) ??
            MESSAGES.SOMETHING_WENT_WRONG,
        });
        console.error('error in onPasswordReset', error);
      });
  };

  return (
    <>
      <div className="text-[21px] text-[#ccc] border-0 border-solid	 border-b-[1px] border-[#282828] pt-5 mb-5">
        {STRINGS.PASSWROD_RESET}
      </div>
      <p className="text-size-14 text-[#fff]">{STRINGS.ENTER_EMAIL}</p>
      <div className="mb-4">
        <FormElement
          title={
            <span className="text-common-white font-bold color-[#FF6C6C]">
              {COMMON.EMAIL2}:
            </span>
          }
          errorMsg={errors.email?.message}
        >
          <ControlledInput
            control={control}
            name="email"
            isRequired
            placeholder="Email"
            pattern={emailRegex}
            className="!w-[470px] [&>*:first-child]:py-0"
            size="small"
          />
        </FormElement>
      </div>
      <Button
        onClick={handleSubmit(onPasswordReset)}
        className="!bg-[#2a9fd6] !px-3 !py-2 !text-common-white !capitalize hover:bg-[#2386b4]"
      >
        {STRINGS.RESET_PASSWORD}
      </Button>
    </>
  );
};

const ResetPassowrdWrapper = () => {
  const { search, pathname } = useLocation();
  const [params] = useState(() => {
    return clearQueryParams(pathname, search, [
      'userId',
      'resetToken',
      'userName',
      'isStaff',
    ]);
  });

  useEffect(() => {
    pushToOmniture({
      event: 'pageView',
      pageName: 'password_reset',
    });
  }, []);

  return (
    <Header>
      {(params?.userId && params?.resetToken) || params?.userName ? (
        <ConfirmResetPassword
          userId={params?.userId?.toString()}
          resetToken={params?.resetToken?.toString()}
          userName={params?.userName?.toString()}
          isStaff={params?.isStaff === 'true'}
        />
      ) : (
        <InitiatePasswordReset />
      )}
    </Header>
  );
};

// it is used only in register remove type support as of now
const MessageInfo = ({ type = '' }) => {
  const {
    langStringsData: {
      langStrings: {
        Login: { LOGIN: STRINGS },
      },
    },
  } = useContext(AuthContext);

  let msg1 = STRINGS.ACCOUNT_ACTIVATE_MESS1,
    msg2 = STRINGS.ACCOUNT_ACTIVATE_MESS2;

  if (type === 'resetPassword') {
    msg1 = STRINGS.PASS_RESET_LINK_MESS1;
    msg2 = STRINGS.PASS_RESET_LINK_MESS2;
  }

  return (
    <div className="pt-5">
      <Typography variant="body1" className="!text-[#ccc]">
        {msg1}
      </Typography>
      <Typography
        variant="body1"
        className="!my-[10px] !text-common-white hover:underline hover:cursor-pointer !font-semibold"
      >
        {msg2}
      </Typography>
      <Typography
        variant="body1"
        className="!text-size-20 !my-[10px] !text-[#F26722] !font-bold"
      >
        {STRINGS.INGO_TEAM}
      </Typography>
    </div>
  );
};

const ActivateAccount = () => {
  const { pathname, search } = useLocation();
  const {
    langStringsData: {
      langStrings: { Common: COMMON },
    },
  } = useContext(AuthContext);

  const [data, setData] = useState({ loading: false, errorMessage: '' });
  const [activationId] = useState(() =>
    clearQueryParams(pathname, search, 'activationId'),
  );

  useEffect(() => {
    if (activationId) {
      setData(prevData => ({ ...prevData, loading: true }));

      activateUserAccount({ activationId })
        .then(resp => {
          let errorMessage = '';

          // @ts-ignore
          if (resp?.response?.errorDetail) {
            errorMessage = // @ts-ignore
              resp?.response?.errorDetail?.displayMessage ||
              COMMON.MESSAGES.SOMETHING_WENT_WRONG;
          }
          setData({ loading: false, errorMessage });
        })
        .catch(() => {
          setData({ loading: false, errorMessage: '' });
        });
    }
  }, []);

  if (!activationId) {
    navigateTo(routes.onboarding.myProperty);
  }

  if (data.loading) {
    return null;
  }
  return <MessageTempleate errorMessage={data.errorMessage} />;
};

export { ActivateAccount, MessageInfo };
export default ResetPassowrdWrapper;
