import { routes } from 'app/routes';
export const COUNTRY_INDIA = 'India';
export const ONBOARDING_STEPS_HOTEL = {
  STEP_1: {
    percent: 10,
    label: 'Basic Info',
    route: routes.onboarding.basicInfo,
  },
  STEP_2: {
    percent: 20,
    label: 'Location Added',
    route: routes.onboarding.location,
  },
  STEP_3: {
    percent: 30,
    label: 'Amenities Added',
    route: routes.onboarding.amenities,
  },
  STEP_4: {
    percent: 40,
    label: 'Rooms Added',
    route: routes.onboarding.rooms,
  },
  STEP_5: {
    percent: 60,
    label: 'Photos Added',
    route: routes.onboarding.photosAndVideos.base,
  },
  STEP_6: {
    percent: 70,
    label: 'Policies Added',
    route: routes.onboarding.policies,
  },
  STEP_7: {
    percent: 80,
    label: 'Finance Added',
    route: routes.onboarding.finance,
  },
};

export const ONBOARDING_STEPS_HOMESTAY = {
  STEP_1: {
    percent: 10,
    label: 'Basic Info',
    route: routes.onboarding.basicInfo,
  },
  STEP_2: {
    percent: 20,
    label: 'Location Added',
    route: routes.onboarding.location,
  },
  STEP_3: {
    percent: 30,
    label: 'Amenities Added',
    route: routes.onboarding.amenities,
  },
  STEP_4: {
    percent: 40,
    label: 'Rooms Added',
    route: routes.onboarding.rooms,
  },
  STEP_5: {
    percent: 50,
    label: 'Room Details Added',
    route: routes.onboarding.roomDetails,
  },
  STEP_6: {
    percent: 60,
    label: 'Photos Added',
    route: routes.onboarding.photosAndVideos.base,
  },
  STEP_7: {
    percent: 70,
    label: 'Policies Added',
    route: routes.onboarding.policies,
  },
  STEP_8: {
    percent: 80,
    label: 'Finance Added',
    route: routes.onboarding.finance,
  },
};

export const NEW_ONBOARDING_STEPS_HOMESTAY = {
  STEP_1: {
    percent: 10,
    label: 'Basic Info',
    route: routes.onboarding.basicInfo,
  },
  STEP_2: {
    percent: 20,
    label: 'Location Added',
    route: routes.onboarding.location,
  },
  STEP_3: {
    percent: 30,
    label: 'Rooms & Spaces',
    route: routes.onboarding.roomsAndSpaces,
  },
  STEP_4: {
    percent: 40,
    label: 'Photos',
    route: routes.onboarding.photosAndVideos.base,
  },
  STEP_5: {
    percent: 50,
    label: 'Amenities Added',
    route: routes.onboarding.amenities,
  },
  STEP_6: {
    percent: 60,
    label: 'Pricing & Availability',
    route: routes.onboarding.rooms,
  },
  STEP_7: {
    percent: 70,
    label: 'Policies Details Added',
    route: routes.onboarding.policies,
  },
  STEP_8: {
    percent: 80,
    label: 'Finance Added',
    route: routes.onboarding.finance,
  },
};
export const REJECTED_PROPERTY_STATES = ['QC Rejected', 'Vendor Rejected'];

export const MAX_CACHE_AGE = 15000;
export const TIMEOUT_AGE = 5000;

export const COUNTRIES = {
  INDIA: 'india',
};

export const AGREEMENT_TYPE = {
  ONBOARDING: 'Onboarding',
};

export const PROPERTY_TYPES = {
  ROOM: 'room',
};

export const API_METHOD_TYPE = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};
