import React from 'react';
import { CloseIcon, ModalOverlay, ModalWrapper } from './styles';

export interface CustomModalProps {
  children: React.ReactNode;
  width?: string;
  padding?: string;
  closeIcon?: boolean;
  onClose?: () => void;
  visible?: boolean;
  closeOnOverlayClick?: boolean;
}

const CustomModal = ({
  children,
  width,
  padding,
  closeIcon,
  onClose,
  visible,
  closeOnOverlayClick = false,
}: CustomModalProps) => {
  return (
    <>
      {visible && (
        <>
          <ModalWrapper width={width} padding={padding}>
            {closeIcon && <CloseIcon onClick={onClose} />}
            {children}
          </ModalWrapper>
          <ModalOverlay onClick={closeOnOverlayClick ? onClose : undefined} />
        </>
      )}
    </>
  );
};

export default CustomModal;
