import { Typography, Button } from 'connect-web-ui';
import { useLangStrings } from 'utilities/CustomHooks';
import InfoWrapper from '../components/InfoWrapper';
import { navigateTo } from 'utilities/Utils';
import { routes } from 'app/routes';
import { useContext } from 'react';
import { HotelContext } from 'utilities/Context';
import { Skeleton } from '@mui/material';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { Dashboard } from 'utilities/TestIds';

const ManageCompetitors = () => {
  const [STRINGS] = useLangStrings<'Dashboard'>('Dashboard');
  const { compsetData } = useContext(HotelContext);
  const { apiStatus } = compsetData;
  const onManageNowClicked = () => {
    pushToOmniture({
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: Object.values(compsetData.data).length > 2 ? 'manage' : 'add_now',
        type: 'click',
        componentName: 'manage_your_competitors',
      },
    });
    navigateTo(routes.compset.base);
  };
  return (
    <InfoWrapper
      className="mt-2 mb-6 bg-color-white border-[1px] border-solid border-[#D2D1D1]"
      testId={Dashboard.manageYourCompetitors}
    >
      <div className="w-3/4 ">
        <Typography variant="h3" className="mb-1">
          {STRINGS.MANAGE_COMP.HEADER}
        </Typography>
        <Typography variant="body1">
          {STRINGS.MANAGE_COMP.ADD_COMP_INFO}
        </Typography>
      </div>
      <div className="w-1/4 flex justify-end items-center">
        {apiStatus.fetching ? (
          <Skeleton variant="rectangular" className="w-[97px] h-[40px]" />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={onManageNowClicked}
          >
            {Object.values(compsetData.data).length > 2
              ? STRINGS.MANAGE_COMP.MANAGE_COMP
              : STRINGS.MANAGE_COMP.ADD_COMP}
          </Button>
        )}
      </div>
    </InfoWrapper>
  );
};

export default ManageCompetitors;
