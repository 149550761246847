import React from 'react';
import {
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
} from 'recharts';

export const CustomComposedChart = ({
  data,
  charts,
  xAxis,
  yAxis,
  height,
  width,
  legend,
  toolTip,
}) => {
  width = width ? width : 600;
  height = height ? height : 250;
  xAxis = xAxis ? xAxis : '';
  yAxis = yAxis ? yAxis : ''; //eslint-disable-line
  legend = legend ? (
    legend
  ) : (
    <Legend
      width={width}
      align="left"
      verticalAlign="top"
      iconType="circle"
      wrapperStyle={{ bottom: height, top: 5 }}
      className="custom-chart-legend"
    />
  );

  toolTip = toolTip ? (
    <Tooltip cursor={false} content={toolTip} />
  ) : (
    <Tooltip cursor={false} />
  );

  return (
    <div className="sr-panel-body">
      <ComposedChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 50 }}
        className="custom-chart-font"
      >
        <CartesianGrid stroke="#f5f5f5" horizontal={false} vertical={false} />
        <XAxis dataKey={xAxis} padding={{ top: 50, bottom: 50 }} />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        {toolTip}
        {legend}
        {charts.map(chart => chart)}
      </ComposedChart>
    </div>
  );
};
