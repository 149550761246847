const { VITE_DYNAMIC_BFF_ORIGIN } = import.meta.env;
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import baseAxios, {
  handleFailure,
  handleSuccess,
  sendApiOrQueryErrorOmnitureEvent,
} from '../base/APIClient';
import {
  generateCorrelationKey,
  getAPIToken,
  isNullOrUndefined,
  showGlobalLoader,
} from 'utilities/Utils';
import { showBffMessage } from './utils';
const lang = localStorage.getItem('ingo_user_lang') || 'En';

const reqInterceptor = (config: AxiosRequestConfig) => {
  const token = getAPIToken();
  if (config.url.indexOf(`strings/${lang}`) === -1 && token) {
    config.headers.Authorization = `Token ${token}`;
  }
  config.headers.requestid = generateCorrelationKey();

  if (config.useLoader) {
    showGlobalLoader(true);
  }

  return config;
};

const { common } = baseAxios.defaults.headers;

const axiosInstance = axios.create();

axiosInstance.defaults.headers.common = common as AxiosRequestHeaders;

axiosInstance.interceptors.request.use(reqInterceptor);
axiosInstance.interceptors.response.use(handleSuccess, handleFailure);

type Config = {
  url: string;
  data?: Object;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  useLoader?: boolean;
  showMessage?: boolean;
  params?: Object;
};
//********** API Wrapper ****************
export function apiWrapper(config: Config, omnitureData = null) {
  omnitureData = isNullOrUndefined(omnitureData)
    ? { cta: {} }
    : { ...omnitureData };
  return new Promise((resolve, reject) => {
    const {
      url,
      data,
      method,
      useLoader = false,
      params,
      ...otherOptions
    } = config;
    const { showMessage = true } = otherOptions;

    const axiosConfig: AxiosRequestConfig = {
      url,
      data,
      method,
      params,
    };
    if (useLoader) {
      showGlobalLoader(true);
    }
    axiosInstance(axiosConfig)
      .then(resp => {
        const { data: _data, request } = resp;
        const { success, display } = _data;

        if (showMessage) {
          showBffMessage(display);
        }
        if (success) {
          resolve(_data);
          return;
        }
        omnitureData.cta.misc_id_3 = request?.responseURL;
        sendApiOrQueryErrorOmnitureEvent({ error: resp, omnitureData }); // test this once actual BE API is deployed
      })
      .catch(err => {
        omnitureData.cta.misc_id_3 = err?.request?.responseURL;
        sendApiOrQueryErrorOmnitureEvent({ error: err, omnitureData }); // test this once actual BE API is deployed
        reject(err?.response?.data);
      })
      .finally(() => {
        if (useLoader) {
          showGlobalLoader(false);
        }
      });
  });
}

export const setBffApiClientCommonHeader = () => {
  const headers = {
    'meta-data-brand': 'INGO',
    source: 'ingo_web',
    platform: 'Desktop',
    language: lang,
    country: 'in',
  };
  axiosInstance.defaults.headers.common = headers;
};

export default apiWrapper;

export const bffDynamicOrigin = VITE_DYNAMIC_BFF_ORIGIN;
