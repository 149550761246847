import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Typography, Button } from 'connect-web-ui';
import * as CommonIcons from 'assets/common';
import { ArgumentTypes } from 'utilities/Utils.types';

type DisplaySectionProps = {
  title;
  showAction?: boolean;
  onActionClick?: () => void;
  isNavigation?: boolean;
  titleDetails?: ReactNode;
  children?: ReactNode;
  isAddInfo?: boolean;
  noButtons?: boolean;
  titleThemeColor?: ArgumentTypes<typeof Typography>['themeColor'];
  largeTitleMargin?: boolean;
  dataTourId?: string;
  persuasionInfoDes?: ReactNode;
  dataTestId?: string;
  btnText?: string;
  showIcon?: boolean;
  count?: number;
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    flex: '1',
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: theme.spacing(0),
    },
  },
  persuasionWrapper: {
    background: '#EDF6FF',
    padding: theme.spacing(1),
    border: '1px solid #B2DCFF',
    borderRadius: '4px',
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function DisplaySection(props: DisplaySectionProps) {
  const {
    title,
    titleDetails,
    showAction = true,
    onActionClick,
    isNavigation,
    isAddInfo,
    noButtons,
    children,
    titleThemeColor = 'common.black',
    largeTitleMargin = false,
    dataTourId,
    persuasionInfoDes,
    dataTestId,
    btnText = 'Details',
    showIcon = true,
  } = props;
  const { container, persuasionWrapper } = useStyles();

  return (
    <Box
      className={container}
      data-tour-id={dataTourId}
      data-test-id={dataTestId}
    >
      <Box display="flex" mb={largeTitleMargin ? 1 : 0.5}>
        <Typography variant="body1" fb themeColor={titleThemeColor}>
          {title}
        </Typography>
        {titleDetails}

        {showAction && (
          <Box ml="auto">
            {isAddInfo || noButtons ? null : (
              <Button
                variant="text"
                size="small"
                onClick={onActionClick}
                className={`${isNavigation ? '!-mr-1' : ''}`}
              >
                {isNavigation ? (
                  <>
                    {btnText}{' '}
                    {showIcon && (
                      <CommonIcons.ChevronRight
                        height="16px"
                        width="16px"
                        fill="#008CFF"
                      />
                    )}
                  </>
                ) : (
                  'Edit'
                )}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box color="gray.main">
        {isAddInfo ? (
          <>
            <Button variant="text" onClick={onActionClick}>
              Add Info
            </Button>
            {persuasionInfoDes ? (
              <Box className={persuasionWrapper}>
                <Box className="mr-2">
                  <CommonIcons.InfoBulb />
                </Box>
                <Typography variant="body1" fb>
                  {persuasionInfoDes}
                </Typography>
              </Box>
            ) : null}
          </>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
}
