import { useEffect, useReducer, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { Paper, Popover, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  FeatureTourProps,
  FeatureContentWrapperProps,
  Step,
  Styles,
  WrapperStyles,
  PopoverSize,
} from './FeatureTour.interface';
import { initialState, reducer } from './FeatureTour.reducer';
import { Button, Checkbox, Typography } from 'connect-web-ui';
import { isNullOrUndefined } from 'utilities/Utils';

const zIndex = 9999999991;
const useStyles = makeStyles(() => {
  return {
    selectedStep: (props: Styles) => {
      return {
        position: 'fixed',
        boxShadow: props.showBackdrop ? '0 0 0 9999px rgba(0,0,0,0.8)' : 'none',
        borderRadius: '4px',
        top: props.selectedEl?.top - 8,
        left: props.selectedEl?.left - 8,
        width: props.selectedEl?.width + 15,
        height: props.selectedEl?.height + 15,
        zIndex: zIndex + 3,
      };
    },
    content: (props: Styles) => {
      return {
        padding: props.noPadding ? 0 : '4px 16px',
      };
    },
    actionFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      borderTop: '1px solid #D2D1D1',
      marginTop: '4px',
    },
    _actionFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      borderTop: 'none',
      marginTop: '4px',
    },
    tourInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '4px 16px',
      height: '32px',
    },
    stepInfo: {
      color: '#212121',
      fontSize: 10,
      margin: '0 12px',
      marginLeft: 'auto',
    },
    previousButton: {
      marginRight: 4,
    },
  };
});

const getWidth = (popoverSize: PopoverSize) => {
  switch (popoverSize) {
    case 'medium':
      return 450;

    case 'large':
      return 600;

    default:
      return 300;
  }
};

const useWrapperStyles = makeStyles(() => {
  const caretSize = 32;
  const offset = 16;
  return {
    popover: (props: WrapperStyles & { showCheckbox?: boolean }) => {
      const { popoverSize, anchorHorizontal, showCheckbox } = props;
      const overflowY = showCheckbox ? 'unset' : 'auto';
      const overflowX = showCheckbox ? 'unset' : 'hidden';
      const marginLeft = showCheckbox
        ? undefined
        : anchorHorizontal === 'right'
          ? offset
          : -offset;
      const marginTop = showCheckbox ? '24px' : undefined;

      const width = getWidth(popoverSize);
      return {
        position: 'relative',
        width,
        marginLeft,
        marginTop,
        overflowY,
        overflowX,
      };
    },
    caret: (props: WrapperStyles) => {
      let left = props.selectedEl?.left - offset;

      let transform = '';
      if (props.anchorHorizontal === 'right') {
        left += props.selectedEl?.width;
        transform = 'rotate(180deg)';
      } else {
        left -= caretSize / 2;
      }
      const top =
        props.selectedEl?.top + props.selectedEl?.height / 2 - caretSize / 2;

      return {
        color: '#ffffff',
        position: 'fixed',
        left,
        top,
        transform,
        width: caretSize,
        height: caretSize,
      };
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
    popper: {
      position: 'relative',
      zIndex: 10000001,
      '&[x-placement*="right"] $arrow': {
        left: offset / 2,
        top: '50%',
        bottom: '50%',
        marginLeft: '-0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%',
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: offset / 2,
        top: '50%',
        bottom: '50%',
        marginRight: '-0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0',
        },
      },
    },
    // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
    arrow: (props: WrapperStyles) => ({
      overflow: 'hidden',
      position: 'absolute',
      boxSizing: 'border-box',
      color: '#fff',
      transform: props.anchorHorizontal === 'right' ? 'rotate(180deg)' : '',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2),
        0px 8px 10px 1px rgba(0,0,0,0.14),
        0px 3px 14px 2px rgba(0,0,0,0.12)`,
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
      },
    }),
  };
});

function FeatureContentWrapper(
  props: FeatureContentWrapperProps & { showCheckbox?: boolean },
) {
  const {
    children,
    showBackdrop,
    anchorEl,
    popoverState,
    classes,
    showCheckbox,
  } = props;
  const arrowRef = useRef(null);

  if (showBackdrop) {
    if (showCheckbox) {
      // For tooltip view with bottom-left placement
      return (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
          style={{
            zIndex: zIndex + 1000,
            overflowX: 'unset',
            overflowY: 'unset',
          }}
          open
        >
          <ArrowRight
            style={{
              position: 'absolute',
              color: '#3543bf',
              top: '-6px',
              width: '12px',
              height: '12px',
              margin: '0 144.9px 133px 145px',
              transform: 'rotate(-315.34deg)',
              border: 'solid 1px #3543bf',
              backgroundColor: '#3543bf',
            }}
          />
          {children}
        </Popover>
      );
    }
    // For regular feature tour
    return (
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: popoverState.anchorVertical,
          horizontal: popoverState.anchorHorizontal,
        }}
        transformOrigin={{
          vertical: popoverState.transformVertical,
          horizontal: popoverState.transformHorizontal,
        }}
        classes={{ paper: classes.popover }}
        style={{ zIndex: zIndex + 1000 }}
        open
      >
        <ArrowRight className={classes.caret} />
        {children}
      </Popover>
    );
  }

  // For tooltip view
  return (
    <Popper
      anchorEl={anchorEl}
      placement={popoverState.anchorHorizontal}
      className={classes.popper}
      open
    >
      <Paper className={classes.popover}>
        <ArrowRight className={classes.arrow} ref={arrowRef} />
        {children}
      </Paper>
    </Popper>
  );
}
const portal = document.getElementById('react-portal');

const localStorageKey = 'feature-tour';
export function getTourStorage() {
  function _getData() {
    const data = localStorage.getItem(localStorageKey);
    if (isNullOrUndefined(data)) {
      return {};
    }
    return JSON.parse(data);
  }

  function get<T>(saveKey: string): T {
    const parsedData = _getData();
    return parsedData[saveKey];
  }

  function set(saveKey: string, value: unknown = true) {
    const parsedData = _getData();
    const updatedData = { ...parsedData, [saveKey]: value };
    localStorage.setItem(localStorageKey, JSON.stringify(updatedData));
  }

  function unset(saveKey: string) {
    const parsedData = _getData();
    delete parsedData[saveKey];
    localStorage.setItem(localStorageKey, JSON.stringify(parsedData));
  }

  return {
    get,
    set,
    unset,
  };
}

function FeatureTour(props: FeatureTourProps & { showCheckbox?: boolean }) {
  const {
    steps,
    onClose,
    size = 'small',
    hideCount = false,
    noPadding = false,
    showCheckbox = false,
    onNextClick = () => {},
    onPrevClick = () => {},
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCheckboxToggle = () => {
    setDontShowAgain(!dontShowAgain);
    props.onCheckboxClick(); // Call the provided onCheckboxClick callback
  };

  const currentStep = steps[state.stepIndex];
  const popoverSize = currentStep?.size ?? size;

  const showBackdrop = !currentStep.isTooltip;
  const classes = useStyles({
    selectedEl: state.selectedEl,
    noPadding,
    showBackdrop,
  });

  const wrapperClasses = useWrapperStyles({
    selectedEl: state.selectedEl,
    anchorHorizontal: state.popoverState.anchorHorizontal,
    popoverSize,
    showCheckbox: props.showCheckbox,
  });

  const endTour = () => {
    dispatch({ type: 'reset' });
    onClose(currentStep); // Close the tour
  };

  const moveNext = () => {
    dispatch({ type: 'next' });
    onNextClick(currentStep);
  };

  const movePrevious = () => {
    dispatch({ type: 'previous' });
    onPrevClick(currentStep);
  };

  const observer = new IntersectionObserver(
    entry => {
      entry.forEach(element => {
        // scrollIntoView use if not element is not visible
        if (!element.isIntersecting) {
          dispatch({ type: 'reset_dimension' });
          // Due to above scroll happen is a blocking behavior and async in nature, making re observing post to scroll completion.
          // Idea: only one execution context is executed in stack, Thus pushing this into different context using timer function ()
          setTimeout(() => {
            element.target.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, 0);
        } else {
          dispatch({
            type: 'compute_dimension',
            data: {
              anchorEl: element.target,
              selectedEl: element.boundingClientRect,
            },
          });
          // Remove observation once event triggered.
          observer.unobserve(element.target);
        }
      });
    },
    {
      threshold: 1,
    },
  );

  const observeTarget = (_currentStep: Step) => {
    // Selector
    const el = document.querySelector(
      `[data-tour-id="${_currentStep.tourId}"]`,
    );
    if (el) {
      observer.observe(el);
    } else {
      // currentStep.tourId doesn't exist
      endTour();
    }
  };

  useEffect(() => {
    const tourStorage = getTourStorage();
    const dontShowAgainValue = tourStorage.get<boolean>('dontShowAgain');
    if (dontShowAgainValue) {
      onClose(currentStep);
    }
  }, [currentStep, onClose]);

  useEffect(() => {
    if (currentStep) {
      observeTarget(currentStep);
    }
  }, [state.stepIndex]);

  if (steps.length === 0 || isNullOrUndefined(state.selectedEl)) {
    return null;
  }

  const content = (
    <div>
      {showBackdrop && <div className={classes.selectedStep} />}
      <FeatureContentWrapper
        showBackdrop={showBackdrop}
        anchorEl={state.anchorEl}
        popoverState={state.popoverState}
        classes={wrapperClasses}
        showCheckbox={showCheckbox}
      >
        <div className={showCheckbox ? 'bg-[#3543bf]' : ''}>
          <div className={!showCheckbox && classes.tourInfo}>
            {state.stepIndex !== steps.length - 1 && !showCheckbox && (
              <Button onClick={endTour} variant="text" size="small">
                Skip tour
              </Button>
            )}

            {!hideCount && (
              <p className={classes.stepInfo}>
                {state.stepIndex + 1} of {steps.length}
              </p>
            )}
          </div>
          <div className={classes.content}>
            {steps?.[state.stepIndex]?.content}
          </div>

          <div
            className={
              showCheckbox ? classes._actionFooter : classes.actionFooter
            }
          >
            {showCheckbox ? (
              <div className="flex items-center">
                <Checkbox
                  checked={dontShowAgain}
                  onChange={handleCheckboxToggle}
                  className="bg-[#FFFFFF]"
                />
                <Typography variant="subtitle1" className="!text-common-white">
                  Don't show this again
                </Typography>
              </div>
            ) : (
              // Previous button
              <Button
                onClick={movePrevious}
                className={classes.previousButton}
                disabled={state.stepIndex === 0}
                variant="text"
                size="small"
              >
                previous
              </Button>
            )}

            <Button
              onClick={
                state.stepIndex === steps.length - 1 ? endTour : moveNext
              }
              className={
                showCheckbox
                  ? '!text-[#3543bf] bg-[#FFFFFF] h-6 hover:bg-color-white !capitalize'
                  : ''
              }
              size="small"
            >
              {state.stepIndex === steps.length - 1
                ? showCheckbox
                  ? 'Got It'
                  : 'Done'
                : showCheckbox
                  ? 'Got It'
                  : 'next'}
            </Button>
          </div>
        </div>
      </FeatureContentWrapper>
    </div>
  );

  return createPortal(content, portal);
}

export default FeatureTour;
