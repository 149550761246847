import { type ReactElement } from 'react';
import { classNames } from 'utilities/Utils';

type Props = {
  className?: string;
  twoColLayout?: boolean;
  children: ReactElement | ReactElement[];
  widthSrContainer?: boolean;
  stickySideTab?: boolean;
};

export const NewContainer = (props: Props) => {
  const {
    className,
    twoColLayout = false,
    children,
    widthSrContainer,
    stickySideTab = false,
  } = props;
  const reReturn = (
    <div
      className={classNames('new-container', widthSrContainer ? '' : className)}
    >
      {Array.isArray(children) || twoColLayout ? (
        <>
          <div id="main-content">{twoColLayout ? children : children[0]}</div>
          <div id="side-content" className={stickySideTab ? 'side-sticky' : ''}>
            {twoColLayout ? null : children[1]}
          </div>
        </>
      ) : (
        <div className="core-content-width">{children}</div>
      )}
    </div>
  );
  return widthSrContainer ? (
    <div
      className={classNames(
        'new-sr-container',
        !widthSrContainer ? '' : className,
      )}
    >
      {reReturn}
    </div>
  ) : (
    reReturn
  );
};
