import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { clearQueryParams } from 'utilities/Utils';
import PartnerOfferings from './components/PartnerOfferings';
import Features from './components/Features';
import Faqs from './components/Faqs';
import Footer from './components/Footer';
import FooterBar from './components/FooterBar';
import { LandingPageWrapper } from './LandingPage.styles';
import DownloadAppComponent from './components/DownloadApp';
import HeaderSection from './components/Header';
import PageHeaderSection from './components/PageHeader';
import useStrings from './hooks/useStrings';
import CustomToast from 'components/cosmos-components/CustomToast';
import { useActivateUser } from './api/landingPageReactQueryHooks';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { COMMON_OMNITURE_VARIABLES } from './constants';

import '../../style/zeplin-token.scss';

const LandingPageScreen = () => {
  const { pathname, search } = useLocation();
  const [scrollY, setScrollY] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const landingPageRef = useRef(null);
  const featuresRef = useRef(null);
  const successStoryRef = useRef(null);
  const downloadAppRef = useRef(null);
  const pageHeaderRef = useRef(null);

  const [STRINGS] = useStrings();

  const { mutate: activateUser } = useActivateUser({
    onSuccess: () => setShowToast(true),
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = landingPageRef.current?.scrollTop;

      setScrollY(scrollTop);
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const scrollToElement = (section: string) => {
    const FEATURE_TO_SECTION_MAP = {
      [STRINGS.header.navLinks.features]: {
        ref: featuresRef,
        omnitureCtaName: 'features',
      },
      [STRINGS.header.navLinks.success_stories]: {
        ref: successStoryRef,
        omnitureCtaName: 'success_stories',
      },
      [STRINGS.header.download_app]: {
        ref: downloadAppRef,
        omnitureCtaName: 'download_app',
      },
      [STRINGS.header.signUp]: {
        ref: pageHeaderRef,
        omnitureCtaName: 'sign_up',
      },
    };

    if (!FEATURE_TO_SECTION_MAP[section]) {
      return;
    }

    if (section !== STRINGS.header.signUp) {
      FEATURE_TO_SECTION_MAP[section].ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    } else {
      FEATURE_TO_SECTION_MAP[section].ref.current?.toggleSignUpModal();
    }

    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: FEATURE_TO_SECTION_MAP[section].omnitureCtaName,
        type: 'click',
        componentName: 'header',
      },
    });
  };

  useEffect(() => {
    const activationId = clearQueryParams(pathname, search, 'activationId');

    if (activationId) {
      activateUser({ activationId });
    }
  }, [pathname, search, activateUser]);

  useEffect(() => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.PAGE_VIEW,
    });
  }, []);

  return (
    <LandingPageWrapper ref={landingPageRef}>
      <span id="top" />
      <HeaderSection scrollY={scrollY} scrollToElement={scrollToElement} />
      <PageHeaderSection pageHeaderRef={pageHeaderRef} />
      <PartnerOfferings />
      <Features featuresRef={featuresRef} />
      {/* <SuccessStoriesSection successStoryRef={successStoryRef} /> */}
      {/* <CeoMessageSection /> */}
      <Faqs />
      <DownloadAppComponent downloadAppRef={downloadAppRef} />
      <Footer />
      <FooterBar />
      <CustomToast
        visible={showToast}
        message={STRINGS.pageHeader.accountConfirmed}
        onClose={() => setShowToast(false)}
      />
    </LandingPageWrapper>
  );
};

export default LandingPageScreen;
