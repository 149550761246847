import { showError } from 'base/APIClient';
import { useFormContext } from 'react-hook-form';
import { ApiAction, ClientSideAction, NavigationAction } from 'bff';
import apiWrapper from 'bff/APIClient';
import { pushToOmnitureBFF, showBffMessage } from 'bff/utils';
import { ApiResponseType } from 'shared_logic/CP/types';
import { getURLWithParams, goBack, navigateTo } from 'utilities/Utils';
import { useCallback } from 'react';
import { OtherProps } from 'bff/types/types';

export const useClientSideAction = () => {
  const { handleSubmit } = useFormContext() || {};

  const handleAPIAction = useCallback(
    async (action: ApiAction, values: Object, otherProps?: OtherProps) => {
      const additionalData = action?.additionalData || {};
      const url = action.url;
      let payload: Object = {};
      if (otherProps?.createPayload) {
        payload = otherProps.createPayload?.(values, additionalData);
      } else {
        payload = { ...additionalData, ...values };
      }

      try {
        const response = (await apiWrapper({
          url: `http://localhost:8080${url}`,
          method: action?.method ?? 'GET',
          data: payload,
        })) as ApiResponseType;

        if (response.success) {
          showBffMessage(response.display);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await handleClientSideAction(action.onSuccess);
        }
      } catch (e) {
        if (e?.success === false && e?.display) {
          showBffMessage(e.display);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await handleClientSideAction(action.onFailure);
        } else {
          showError('Something went wrong');
        }
      }
    },
    [],
  );

  const handleNavigation = useCallback((action: NavigationAction) => {
    if (action?.url === 'GO_BACK') {
      goBack();
    } else {
      const url = getURLWithParams(action.url, action?.navParams || {});
      navigateTo(url);
    }
  }, []);

  const handleClientSideAction = useCallback(
    (
      action: ClientSideAction,
      customFunc: (action: ClientSideAction) => void = () => {},
      otherProps?: OtherProps,
    ) => {
      pushToOmnitureBFF(action?.trackingData);
      switch (action?.type) {
        case 'API_CALL':
          return handleSubmit(values =>
            handleAPIAction(action, values, otherProps),
          )();
        case 'NAVIGATION':
          return handleNavigation(action);
        case 'CUSTOM':
          return customFunc(action);
        default:
      }
    },
    [handleAPIAction, handleNavigation, handleSubmit],
  );

  return {
    handleClientSideAction,
    handleAPIAction,
  };
};
