import { Button } from 'connect-web-ui';
import { useContext } from 'react';
import { AuthContext } from 'utilities/Context';
import { navigateTo } from 'utilities/Utils';
import { routes } from 'app/routes';
import { pushToCtaOmnitureCommon } from '../utils';
import { ConnectWhite } from 'assets/modules/landing';

import './login.scss';

const Header = ({ children }) => {
  const {
    langStringsData: {
      langStrings: {
        Login: { LOGIN: STRINGS },
      },
    },
    isLoggedIn,
    logOutUser,
  } = useContext(AuthContext);

  const handleLogin = () => {
    pushToCtaOmnitureCommon({
      ctaName: 'Sign_In',
      componentName: 'Forgot_Password|Sign_In',
      pageName: 'header_signin',
    });
    navigateTo(routes.login.base);
  };

  const logoClickHandle = () => {
    pushToCtaOmnitureCommon({
      ctaName: 'Header_Logo',
      componentName: 'Forgot_Password|Header_Logo',
      pageName: 'header_logo_click',
    });
    navigateTo('/');
  };

  return (
    <div>
      <div className="bg-[#252830]">
        <div className=" mx-auto">
          <div className="flex justify-between px-[15px] pt-[5px] pb-[15px] item-width mx-auto">
            <ConnectWhite
              className="mt-3 cursor-pointer"
              onClick={() => logoClickHandle()}
            />

            <Button
              variant="text"
              className="h-10 my-auto"
              onClick={() => (isLoggedIn ? logOutUser() : handleLogin())}
            >
              {isLoggedIn ? STRINGS.SIGN_OUT : STRINGS.SIGN_IN}
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-[#060606] h-[calc(100vh-88px)]">
        <div className="mx-auto px-[15px] item-width">{children}</div>
      </div>
    </div>
  );
};

export default Header;
