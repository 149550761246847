import { useQuery } from '@tanstack/react-query';
import axios, { apiWrapper } from 'base/APIClient';
import { APP_MAIN } from 'base/Url';
import type {
  IFetchLeftNavPaylod,
  ILeftNavDataRequest,
  ILeftNavDataResponse,
} from './SideNav.types';
import { isNullOrUndefined } from 'utilities/Utils';
import { RETRY_COUNT } from './SideNav.constants';
import { useContext, useMemo } from 'react';
import { AppContext } from 'utilities/Context';
import { useLocation } from 'react-router-dom';
import { routes } from 'app/routes';
import { getDefaultSideNavData } from './leftNavMockData';

type UseGetLeftNavData =
  | {
    currentHotelCode: '';
    showResellerView: false;
    isGrpPropertyView: true;
  }
  | {
    showResellerView: boolean;
    currentHotelCode: string;
    isGrpPropertyView: false;
  };

type GetLeftNavQueryKeys = {
  hotelcode: string;
  showResellerView: boolean;
  showBdView: boolean;
  isGrpPropertyView: boolean;
};
const getLeftNavQueryKeys = (keys: GetLeftNavQueryKeys) => [
  'query: leftNavData',
  ...Object.entries(keys).map(([key, value]) => `${key}: ${value}`),
];

const fetchLeftNavData = async ({
  hotelcode,
  showResellerView = false,
  showBdView = false,
}: IFetchLeftNavPaylod): Promise<ILeftNavDataResponse> => {
  const url = APP_MAIN.LEFTNAV_API_ENDPOINT;
  const payload: ILeftNavDataRequest = {
    hotelDetail: {
      hotelcode,
    },
    metadata: {
      showResellerView,
      showBdView,
    },
  };

  return apiWrapper<ILeftNavDataResponse>(
    axios.post(url, payload, { useLoader: false }),
  );
};

export const useGetLeftNavData = ({
  currentHotelCode,
  isGrpPropertyView,
  showResellerView,
}: UseGetLeftNavData) => {
  const { pathname } = useLocation();
  const { isBdManager } = useContext(AppContext);

  const showBdView =
    isGrpPropertyView && isBdManager && pathname.includes(routes.parity.base);
  const hotelcode = isGrpPropertyView ? '' : currentHotelCode;

  const queryKey = getLeftNavQueryKeys({
    hotelcode,
    showResellerView,
    showBdView,
    isGrpPropertyView,
  });

  const queryFn = () =>
    fetchLeftNavData({
      hotelcode,
      showResellerView,
      showBdView,
    });

  const {
    data: leftNavData,
    isFetched: isLeftNavDataLoaded,
    failureCount,
    error,
  } = useQuery({
    queryKey,
    queryFn,
    enabled: isGrpPropertyView || !isNullOrUndefined(hotelcode),
    staleTime: Infinity,
    gcTime: Infinity,
    retry: RETRY_COUNT,
    retryDelay: retryCount => 2_000 ** retryCount,
    throwOnError: false,
  });

  const fallbackResponse = useMemo(() => {
    const fallbackRes = getDefaultSideNavData();
    return {
      leftNavData: fallbackRes,
      isLeftNavDataLoaded: true,
    };
  }, []);

  if (failureCount === RETRY_COUNT || error) {
    return fallbackResponse;
  }

  return { leftNavData, isLeftNavDataLoaded };
};
