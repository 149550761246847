import { Modal } from 'connect-web-ui';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';
import { getFormattedDate, unGroupDates } from 'utilities/DateUtils';

export const confirmBoosterType = {
  DATE: 'date',
  DATE_LIST: 'dateList',
  OBJECT: 'object',
  STRINGS: 'strings',
  NUMBER: 'number',
};

const confirmBoosterPopup = obj => {
  return Object.keys(obj).map(offer => {
    const { type, initialValue, updatedValue, data, suffix = '' } = obj[offer];
    if (type === confirmBoosterType.DATE) {
      return (
        <p>
          {offer}:{' '}
          <strong>
            {getFormattedDate(updatedValue, CONSTANTS.BOOSTER_DATE_FORMAT)}
          </strong>
          {suffix}
        </p>
      );
    } else if (type === confirmBoosterType.DATE_LIST) {
      const updatedValueList = unGroupDates(updatedValue)
        .map(date => getFormattedDate(date, CONSTANTS.BOOSTER_DATE_FORMAT))
        .join(', ');
      return (
        <p>
          {offer}: <strong>{updatedValueList}</strong> {suffix}
        </p>
      );
    } else if (type === confirmBoosterType.OBJECT) {
      return <>{confirmBoosterPopup(data)}</>;
    } else if (type === confirmBoosterType.NUMBER) {
      return (
        <p>
          {offer}:{' '}
          {initialValue && (
            <>
              <strong>{initialValue}%</strong> to{' '}
            </>
          )}
          <strong>{updatedValue}%</strong> {suffix}
        </p>
      );
    }
    return (
      <p>
        {offer}:{' '}
        {initialValue && (
          <>
            <strong>{initialValue}</strong> to{' '}
          </>
        )}
        <strong>{updatedValue}</strong> {suffix}
      </p>
    );
  });
};

export default function ConfirmBooster(props) {
  const {
    data,
    onClose,
    onConfirm,
    title = 'Continue to Offer Discount',
  } = props;

  return (
    <Modal
      isOpen
      size="small"
      variant="confirm"
      header={{
        title: title,
      }}
      footer={{
        primaryBtn: {
          text: 'Confirm',
          onClick: onConfirm,
        },
        secondaryBtn: {
          onClick: onClose,
        },
      }}
    >
      <div className="confirm-discount-modal">{confirmBoosterPopup(data)}</div>
    </Modal>
  );
}
