/*
  Import required CB environment variables from .env file
*/
const { VITE_CONTENT_BFF_ORIGIN: CONTENT_CB_BASE_URL } = import.meta.env;

const ONBOARDING = {
  ENROLL_INFO: (draftId = '') =>
    `${CONTENT_CB_BASE_URL}/api/v3/enroll/basic-info${
      draftId ? `/${draftId}` : ''
    }`,
  ENROLL_HOTEL: (hotelCode = '') =>
    `${CONTENT_CB_BASE_URL}/api/v3/enroll/location${
      hotelCode ? `/${hotelCode}` : ''
    }`,
  ENROLL_ROOM: (roomId = '') =>
    `${CONTENT_CB_BASE_URL}/api/v3/enroll/room${roomId ? `/${roomId}` : ''}`,
};

export { ONBOARDING };

const { VITE_DYNAMIC_BFF_ORIGIN } = import.meta.env;

// eslint-disable-next-line unused-imports/no-unused-vars

export const BFF_URLS = {
  CANCELLATION: {
    GET_POLICIES_LIST_PAGE: (hotelCode: string) =>
      `${VITE_DYNAMIC_BFF_ORIGIN}/api/v1/web/hotel/${hotelCode}/policy/cancellation/list/`,
    CREATE_CP_PAGE: (hotelCode: string) =>
      `${VITE_DYNAMIC_BFF_ORIGIN}/api/v1/web/hotel/${hotelCode}/policy/cancellation/create`,
    EDIT_CP_PAGE: (hotelCode: string, policyId: string) =>
      `${VITE_DYNAMIC_BFF_ORIGIN}/api/v1/web/hotel/${hotelCode}/policy/cancellation/update/${policyId}`,
  },
};
