import { useCardStyles } from 'modules/host-web/styles';
import { Box } from '@mui/material';
import { Typography, Divider, Tooltip } from 'connect-web-ui';
import ContentScoreDisplay from './ContentScoreDisplay';
import Button from '@mui/material/Button';
import * as CS from 'assets/modules/contentScore';
import { useLangStrings } from '../../../../../utilities/CustomHooks';
import { navigateTo } from 'utilities/Utils';
import { ContentScoreRoutes } from './ContentScoreRoutes';
import { Property } from 'utilities/TestIds';

import './ContentScore.scss';

export const csColors = {
  1: { bgColor: 'backgrounds.error', color: 'error.main' },
  2: { bgColor: 'backgrounds.lightError', color: 'common.lightError' },
  3: { bgColor: 'backgrounds.warning', color: 'warning.main' },
  4: { bgColor: 'backgrounds.lightSuccess', color: 'common.lightSuccess' },
  5: { bgColor: 'backgrounds.success', color: 'success.main' },
};

export const csTexts = {
  5: {
    title: 'Excellent score!',
    subtext: 'You are in top 5% properties in your locality. Keep it up!',
  },
  4: {
    title: 'Good score',
    subtext: 'You are ahead of 64 properties near you, but you can do better!',
  },
  3: {
    title: 'Average score',
    subtext: 'You are ahead of 35 properties near you, but you can do better!',
  },
  2: {
    title: 'Low score',
    subtext: 'You need to improve your listing content to get more bookings!',
  },
  1: {
    title: 'Critical score!',
    subtext: 'You listing is missing major details which are critical!',
  },
};
export const Card = ({ persuasion, onCloseDrawer }) => {
  const data = persuasion.consumers.hostapp;
  const temp = persuasion.data;

  if (!ContentScoreRoutes?.[temp.category]?.[temp.sub_category]) return null;

  const imgPath =
    ContentScoreRoutes[temp.category][temp.sub_category].imagePath;
  const getRoute =
    ContentScoreRoutes[temp.category][temp.sub_category].getRoute;
  let route = ContentScoreRoutes[temp.category][temp.sub_category]?.route;

  if (getRoute) {
    route = getRoute(temp?.space_id);
  }
  const SVGIcon = CS[imgPath];
  const ArrowSideSVG = CS.ArrowSide;

  const reDirect = () => {
    navigateTo(route);
    if (typeof onCloseDrawer === 'function') {
      onCloseDrawer();
    }
  };

  return (
    <div className="persuasion-card" key={persuasion.id}>
      <Box pt="10px" alignItems="center" display="flex" className="title">
        {imgPath && SVGIcon && <SVGIcon className="SVGIcon" />}
        <span>{data.persuasion_title}</span>
      </Box>
      <div className="Message">{data.message}</div>
      <div className="Button">
        {(data.ctas || []).map(cta => (
          <Button
            color="primary"
            onClick={reDirect}
            className="text-size-14 font-medium	"
          >
            {cta.type}
            <ArrowSideSVG className="ArrowIcon" />
          </Button>
        ))}
      </div>
      <Divider variant="middle" orientation="vertical" className="Divider" />
    </div>
  );
};

export default function ContentScore({ data = null, handleViewAllClick }) {
  const { persuasionCard } = useCardStyles();
  const [
    {
      LISTING_MAIN: { CONTENT_SCORE },
    },
  ] = useLangStrings<'HostWeb'>('HostWeb');
  const {
    persuasions = {},
    totalContentScore: score,
  }: { totalContentScore: number; persuasions: {} } = data;

  const segment = Math.ceil(score / 20) || 1;
  const segmentTexts = csTexts[segment];
  const segmentColors = csColors[segment];
  const ContactSupport = CS.ContactSupport;

  const keys = Object.keys(persuasions);
  const hideContentScoreSubInfo = keys.length === 0;

  return (
    <Box
      className="content-score-container"
      mb={'10px'}
      data-test-id={Property.sideContent.contentScore}
    >
      <Box className={persuasionCard}>
        <Typography
          variant="h3"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {CONTENT_SCORE.TITLE}
          <Tooltip
            content={CONTENT_SCORE.CONTENT_SCORE_CHANGE}
            variant="popover"
          >
            <ContactSupport style={{ marginLeft: '10px' }} />
          </Tooltip>
        </Typography>
        <Typography variant="subtitle1" fb themeColor="gray.light">
          {CONTENT_SCORE.SUB_TITLE}
        </Typography>
        <Box
          padding={2}
          mt={1.5}
          mb={2.5}
          display="flex"
          alignItems="end"
          borderRadius="4px"
          bgcolor={segmentColors.bgColor}
        >
          <Box mr={2.5} className="score-card">
            <Typography variant="h2" themeColor={segmentColors.color}>
              {segmentTexts.title}
            </Typography>
            <Typography variant="subtitle1" themeColor="gray.main">
              {segmentTexts.subtext}
            </Typography>
          </Box>
          <ContentScoreDisplay score={score} />
        </Box>
        {!hideContentScoreSubInfo && (
          <div>
            {keys.length === 0 && score > 90 && (
              <div className="NoPersuasion">{CONTENT_SCORE.PERSUASIONS[0]}</div>
            )}
            {keys.length > 0 && (
              <div
                className="title"
                style={{ paddingBottom: '15px', color: '#4A4A4A' }}
              >
                {CONTENT_SCORE.PERSUASIONS[1]}
              </div>
            )}

            {keys.length > 0 && persuasions[keys[0]] && (
              <Card persuasion={persuasions[keys[0]][0]} onCloseDrawer={null} />
            )}

            {keys.length > 1 && (
              <div className="Button">
                <Button
                  color="primary"
                  onClick={handleViewAllClick}
                  className="text-size-14 font-medium"
                >
                  {CONTENT_SCORE.VIEW_ALL}
                </Button>
              </div>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
}
