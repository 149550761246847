import { Skeleton } from '@mui/material';
import React from 'react';

export function LeftSectionShimmer() {
  return (
    <div className="bg-color-white w-[88px] h-[100%] block overflow-hidden max-h-[calc(100vh-72px)]">
      {[...new Array(8).keys()].map((_, index) => (
        <div
          className="flex flex-col justify-center items-center gap-1 h-[64px]"
          key={index}
        >
          <Skeleton
            height={28}
            width={28}
            variant="rectangular"
            className="rounded"
          />
          <Skeleton
            height={10}
            width={36}
            variant="rectangular"
            className="rounded"
          />
        </div>
      ))}
    </div>
  );
}
