import { Box } from '@mui/material';
import { Typography } from 'connect-web-ui';

export type ContentScoreDisplayProps = {
  score: number;
};

//All 5 collapsed segments
const collapsed1 = (
  <path
    opacity="0.33"
    d="M-1.77031e-06 40.5C-2.02837e-06 32.3815 2.43994 24.4503 7.00324 17.7356L10.475 20.0951C6.38471 26.1138 4.19766 33.2229 4.19766 40.5L-1.77031e-06 40.5Z"
    fill="#EB2026"
  />
);
const collapsed2 = (
  <path
    opacity="0.33"
    d="M7.739 16.6889C12.5121 10.1217 19.1488 5.14062 26.7878 2.39191L28.209 6.34166C21.3617 8.80548 15.4129 13.2703 11.1345 19.1568L7.739 16.6889Z"
    fill="#EB6920"
  />
);
const collapsed3 = (
  <path
    opacity="0.33"
    d="M28.0127 1.97317C35.7357 -0.530016 44.0327 -0.654352 51.8273 1.61629L50.6533 5.64643C43.6666 3.61113 36.2295 3.72258 29.3069 5.96631L28.0127 1.97317Z"
    fill="#F2A526"
  />
);
const collapsed4 = (
  <path
    opacity="0.33"
    d="M53.0493 1.99331C60.7683 4.50892 67.553 9.28631 72.5233 15.7056L69.2042 18.2755C64.7491 12.5215 58.6675 8.23925 51.7486 5.98437L53.0493 1.99331Z"
    fill="#81B466"
  />
);
const collapsed5 = (
  <path
    opacity="0.33"
    d="M73.2872 16.725C78.308 23.649 81.0079 31.9847 81 40.5375L76.8023 40.5336C76.8094 32.8673 74.3893 25.3956 69.889 19.1892L73.2872 16.725Z"
    fill="#37BC8E"
  />
);

//All 5 expanded (or selected) segments
const expanded1 = (
  <path
    d="M-1.77031e-06 40.5C-2.02837e-06 32.3815 2.43994 24.4503 7.00324 17.7356L15.3774 23.4267C11.955 28.4627 10.125 34.4111 10.125 40.5L-1.77031e-06 40.5Z"
    fill="#EB2026"
  />
);
const expanded2 = (
  <path
    d="M7.739 16.6889C12.5121 10.1217 19.1488 5.14062 26.7878 2.39191L30.2159 11.9189C24.4866 13.9805 19.5091 17.7163 15.9293 22.6417L7.739 16.6889Z"
    fill="#EB6920"
  />
);
const expanded3 = (
  <path
    d="M28.0127 1.97317C35.7357 -0.530016 44.0327 -0.654352 51.8273 1.61629L48.9955 11.3372C43.1496 9.63424 36.9268 9.72749 31.1345 11.6049L28.0127 1.97317Z"
    fill="#F2A526"
  />
);
const expanded4 = (
  <path
    d="M53.0493 1.99331C60.7683 4.50892 67.553 9.28631 72.5233 15.7056L64.5174 21.9042C60.7898 17.0897 55.7012 13.5067 49.912 11.62L53.0493 1.99331Z"
    fill="#A9B466"
  />
);
const expanded5 = (
  <path
    d="M73.2872 16.725C78.308 23.649 81.0079 31.9847 81 40.5375L70.875 40.5281C70.8809 34.1136 68.856 27.8618 65.0904 22.6688L73.2872 16.725Z"
    fill="#37BC8E"
  />
);

export default function ContentScoreDisplay(props: ContentScoreDisplayProps) {
  const { score } = props;
  const segment = Math.ceil(score / 20) || 1;

  return (
    <Box width="81px" height="60px" position="relative">
      <svg
        width="81"
        height="81"
        viewBox="0 0 81 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {segment === 1 ? expanded1 : collapsed1}
        {segment === 2 ? expanded2 : collapsed2}
        {segment === 3 ? expanded3 : collapsed3}
        {segment === 4 ? expanded4 : collapsed4}
        {segment === 5 ? expanded5 : collapsed5}
      </svg>
      <Box
        component="span"
        position="absolute"
        left="50%"
        top="18px"
        style={{ transform: 'translateX(-50%)' }}
      >
        <Typography variant="h1">{Math.floor(score)}</Typography>
      </Box>
    </Box>
  );
}
