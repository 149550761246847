import { Add, Remove } from '@mui/icons-material';
import { useState } from 'react';

import {
  LegacyFormElement as FormElement,
  Input,
  Drawer,
  Calendar,
  Button,
} from 'connect-web-ui';
import {
  ConfirmBoosterModal,
  confirmBoosterType,
  DiscardModal,
} from '../../drawers';

import { useLangStrings } from 'utilities/CustomHooks';
import { GAFormatting } from 'utilities/Utils';
import useBoosterCalendar, { CALENDAR_TYPE } from '../useBoosterCalendar';

import { legacyPushToGTM } from 'utilities/gtm';
import CONSTANTS from 'modules/performanceBooster/PerformanceBooster.constants';

const gtmActionType = GAFormatting(CONSTANTS.TYPE.COUPONS);

const { INITIAL_VALUE: initialCouponValue, MIN_OFFER_VALUE: couponMinValue } =
  CONSTANTS.COUPONS_META;

const calendarConfig = {
  hasBookingEndSync: true,
  showMore: [CALENDAR_TYPE.BOOKING_START, CALENDAR_TYPE.BOOKING_END],
  createOnly: [CALENDAR_TYPE.STAY_START, CALENDAR_TYPE.BOOKING_START],
  blackOutOptions: {},
};

export default function CouponDrawer(props) {
  const { data, onDrawerClose, createCoupon, updateCoupon } = props;
  const [STRINGS] = useLangStrings('PerformanceBooster');

  const { offerCode, checkIn, checkOut, bookingStart, bookingEnd, blackOut } =
    data;
  const isEdit = !!data.offerCode;

  const [showMore, setShowMore] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);

  const [offerValue, setOfferValue] = useState(
    () => data.offerValue || initialCouponValue,
  );

  const calendarInitDate = {
    [CALENDAR_TYPE.BLACKOUT]: blackOut,
    [CALENDAR_TYPE.BOOKING_START]: bookingStart,
    [CALENDAR_TYPE.BOOKING_END]: bookingEnd,
    [CALENDAR_TYPE.STAY_START]: checkIn,
    [CALENDAR_TYPE.STAY_END]: checkOut,
  };

  const {
    calendarProps,
    calendarDates,
    generateModifiedDates,
    appendDatePayload,
  } = useBoosterCalendar(calendarInitDate, calendarConfig);

  const {
    [CALENDAR_TYPE.BLACKOUT]: blackoutCalendarProps,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartCalendarProps,
    [CALENDAR_TYPE.BOOKING_END]: bookingEndCalendarProps,
    [CALENDAR_TYPE.STAY_START]: stayStartCalendarProps,
    [CALENDAR_TYPE.STAY_END]: stayEndCalendarProps,
  } = calendarProps;

  const {
    [CALENDAR_TYPE.STAY_START]: stayStartDate,
    [CALENDAR_TYPE.STAY_END]: stayEndDate,
    [CALENDAR_TYPE.BOOKING_START]: bookingStartDate,
  } = calendarDates;
  const { today, MAX_CALENDAR_DATE: maxDate } = CONSTANTS;

  const getModificationList = () => {
    const modificationMap = {};

    // Offer value
    if (offerValue !== data.offerValue) {
      modificationMap[STRINGS.DRAWER.OFFER_VALUE] = {
        apiKey: 'offer_value',
        type: confirmBoosterType.NUMBER,
        initialValue: data.offerValue,
        updatedValue: offerValue,
      };
    }

    generateModifiedDates(modificationMap, isEdit, showMore);
    return modificationMap;
  };

  const drawerCloseHandler = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'close',
    });
    setShowCancelModal(true);
  };

  const performanceActions = modificationMap => {
    if (isEdit) {
      const payload = {};
      for (const modification in modificationMap) {
        const { apiKey, updatedValue: apiKeyValue } =
          modificationMap[modification];
        payload[apiKey] = apiKeyValue;
      }
      updateCoupon(payload, offerCode);
    } else {
      const isOfferAgain = !!data.offerValue;
      const payload = { offer_value: offerValue };
      appendDatePayload(payload, showMore);

      createCoupon(payload, isOfferAgain);
    }
  };

  const isOfferValid = offerValue < couponMinValue;
  const activateCoupon = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'activate',
    });
    // Validation, not added as min, because input won't let to type 1 or 2 (So can't enter 10, 11... 29)
    if (isOfferValid) {
      return;
    }
    const modificationMap = getModificationList();
    if (Object.keys(modificationMap).length !== 0) {
      setConfirmModal(modificationMap);
    } else {
      onDrawerClose();
    }
  };

  const offerValueChange = value => setOfferValue(value);

  const handleShowMore = () => {
    const eventAction = `${gtmActionType}_edit_screen`;
    const eventLabel = showMore
      ? 'advance_settings_hide'
      : 'advance_settings_show';
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel,
    });
    setShowMore(c => !c);
  };

  const offerConfirm = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'confirm',
    });
    performanceActions(confirmModal);
  };

  const offerCancel = () => {
    const eventAction = `${gtmActionType}_confirm`;
    legacyPushToGTM({
      eventCategory: 'web_boostperformance',
      eventAction,
      eventLabel: 'cancel',
    });
    setConfirmModal(null);
  };

  const couponErrorMessage =
    STRINGS.DRAWER_META.GENERIC.INVALID_OFFER_VALUE.replace(
      '__segment__',
      STRINGS.COUPON,
    ).replace('__minValue__', `${couponMinValue}`);

  return (
    <Drawer
      isOpen
      header={{
        title: STRINGS.DRAWER_META.COUPON.TITLE,
        subTitle: STRINGS.DRAWER_META.COUPON.SUB_TITLE,
        hideCross: true,
      }}
      footer={{
        primaryBtn: {
          text: STRINGS.DRAWER_META.COUPON.POSITIVE_TEXT,
          onClick: activateCoupon,
          disabled: isOfferValid,
        },
        secondaryBtn: {
          text: 'Close',
          onClick: drawerCloseHandler,
        },
      }}
      onClose={drawerCloseHandler}
    >
      <FormElement
        className="margin0 marginT28"
        label={STRINGS.DRAWER.COUPON_OFFER_LABEL}
        errorMsg={isOfferValid ? couponErrorMessage : ''}
      >
        <Input
          type="number"
          name="offerValue"
          value={offerValue}
          onChange={offerValueChange}
          placeholder={STRINGS.DRAWER.COUPON_OFFER_LABEL}
          max={CONSTANTS.COUPONS_META.MAX_OFFER_VALUE}
          rightElement="%"
          error={isOfferValid}
          decimalPoints={0}
          disabled={isEdit}
        />
      </FormElement>
      <p className="drawer-persuasion-info-banner">
        {STRINGS.DRAWER.COUPON_OFFER_BANNER}
      </p>
      <FormElement
        className="marginT28"
        label={STRINGS.DRAWER.APPLICABLE_STAY_START}
      >
        <Calendar
          type="input"
          {...stayStartCalendarProps}
          disabledBefore={(bookingStartDate || today) as Date}
          disabled={isEdit}
        />
      </FormElement>
      <FormElement label={STRINGS.DRAWER.APPLICABLE_STAY_END}>
        <Calendar type="input" {...stayEndCalendarProps} />
      </FormElement>
      <FormElement
        className="marginT28"
        label={STRINGS.DRAWER.CHOOSE_BLACKOUT_DATES}
      >
        <Calendar
          type="blackout"
          inputClasses="!w-[250px]"
          {...blackoutCalendarProps}
          disabledBefore={stayStartDate as Date}
          disabledAfter={stayEndDate as Date}
        />
      </FormElement>
      <Button onClick={handleShowMore} variant="text" size="legacy">
        {showMore ? (
          <>
            <Remove className="booster-advance-settings-icon" />
            {STRINGS.DRAWER.HIDE_ADVANCE_SETTINGS}
          </>
        ) : (
          <>
            <Add className="booster-advance-settings-icon" />
            {STRINGS.DRAWER.SHOW_ADVANCE_SETTINGS}
          </>
        )}
      </Button>
      {showMore && (
        <>
          <FormElement
            className="marginT28"
            label={STRINGS.DRAWER.APPLICABLE_BOOKING_START}
          >
            <Calendar
              type="input"
              {...bookingStartCalendarProps}
              disabledAfter={(stayStartDate || maxDate) as Date}
              disabledBefore={today as Date}
              disabled={isEdit}
            />
          </FormElement>
          <FormElement label={STRINGS.DRAWER.APPLICABLE_BOOKING_END}>
            <Calendar
              type="input"
              {...bookingEndCalendarProps}
              disabledBefore={(stayStartDate || today) as Date}
              disabledAfter={(stayEndDate || maxDate) as Date}
            />
          </FormElement>
        </>
      )}
      {showCancelModal && (
        <DiscardModal
          gtmActionType={gtmActionType}
          onDrawerClose={onDrawerClose}
          setShowCancelModal={setShowCancelModal}
        />
      )}
      {confirmModal && (
        <ConfirmBoosterModal
          data={confirmModal}
          onConfirm={offerConfirm}
          onClose={offerCancel}
        />
      )}
    </Drawer>
  );
}
