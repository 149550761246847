export enum CP_PAGE {
  POLICY_LISTING = 'POLICY_LISTING',
  EDIT_POLICY = 'EDIT_POLICY',
  CREATE_POLICY = 'CREATE_POLICY',
}

// currently new CP is not allowed, this is just list of some test hotels.. we will remove this once we allow new CP after feature flag API.
export const allowNewCP = ['1000115873', '1000457509'];

export enum WIDGET_TYPE {
  POLICY_LIST = 'POLICY_LIST',
  ALERT_INFO = 'ALERT_INFO',
}
export enum TAG_COLOR {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  SUCCESSMEDIUM = 'successmedium',
}
