import { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { IngoToConnectBg, IngoToConnectPatternBg } from 'assets/common';
import { connect } from 'assets/lottie';
import { Button, Modal } from 'connect-web-ui';
import Lottie from 'lottie-react';
import { useLangStrings } from 'utilities/CustomHooks';

interface IngoToConnectModalProps {
  showModal: boolean;
  onButtonClick: () => void;
}

const GradientTypography = styled(Typography)(() => ({
  backgroundImage:
    'linear-gradient(195deg, #231557 -29.21%, #44107A 18.29%, #FF1361 81.8%, #FFF800 136.92%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

const IngoToConnectModal: React.FC<IngoToConnectModalProps> = ({
  showModal,
  onButtonClick,
}) => {
  const [showButton, setShowButton] = useState(false);
  const [{ INGO_TO_CONNECT: MODAL_STRINGS }] =
    useLangStrings<'Dashboard'>('Dashboard');

  useEffect(() => {
    const timer = setTimeout(() => setShowButton(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      isOpen={showModal}
      size="custom"
      customClasses={{ paper: 'z-5 !rounded-[12px]' }}
    >
      <Box
        width="600px"
        className="relative rounded -m-4 ingo-connect-modal-dashboard"
      >
        <img
          src={IngoToConnectBg}
          alt="ingo-connect-bg"
          className="absolute inset-0 w-full h-full object-cover z-0"
          loading="lazy"
        />
        <img
          src={IngoToConnectPatternBg}
          alt="ingo-connect-pattern-bg"
          className="absolute inset-0 w-full h-full object-cover z-5"
          loading="lazy"
        />
        <div className="flex relative z-10 px-6 pt-8 pb-7">
          <div className="flex-1">
            <Typography variant="h2" className="!font-normal">
              {MODAL_STRINGS.SAY_HELLO_TO}
            </Typography>
            <Typography variant="h2" className="!font-normal">
              {MODAL_STRINGS.CONNECT}
            </Typography>
            <Typography className="mt-8" variant="body1">
              {MODAL_STRINGS.INGO_MMT_IS_NOW}
              <strong>{MODAL_STRINGS.CONNECT}</strong>
              {MODAL_STRINGS.REFLECTING_COMMITMENT}
            </Typography>
            <Typography className="mt-6">
              {MODAL_STRINGS.NAME_LOOK_CHANGED}
            </Typography>
            <Typography className="mt-9 italic font-extrabold text-size-12">
              {MODAL_STRINGS.DESIGNED_TO_SUPPORT}
            </Typography>
          </div>
          <div className="flex-1 flex flex-col justify-center items-center">
            <Lottie
              animationData={connect}
              style={{ height: '80px', width: '102px' }}
              loop={2}
              className="mt-10"
            />
            <Typography className=" mt-12 !font-normal" variant="h2">
              {MODAL_STRINGS.INGO_MMT}
            </Typography>
            <div className="flex justify-center items-center">
              <Typography className="!font-normal" variant="h2">
                {MODAL_STRINGS.IS_NOW}
              </Typography>
              <GradientTypography className="ml-2 font-extrabold" variant="h2">
                {MODAL_STRINGS.CONNECT}
              </GradientTypography>
            </div>
            <div className="flex-grow"></div>
            {showButton && (
              <Button onClick={onButtonClick}>
                {MODAL_STRINGS.LETS_CONNECT}
              </Button>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default IngoToConnectModal;
