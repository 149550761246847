import React from 'react';
import { AppStore, ConnectNameLogo, PlayStore } from 'assets/modules/landing';
import FooterLinks from '../FooterLinks/FooterLinks';
import {
  FooterBody,
  FooterStyled,
  FooterTop,
  FooterTopRight,
} from './Footer.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import {
  CONTENT_CONFIG,
  EXTERNAL_URLS,
  GET_THE_APP,
  COMMON_OMNITURE_VARIABLES,
} from 'modules/landingPage/constants';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { useIntersectionObserver } from 'utilities/CustomHooks';

const handleSocialClick = socialName => {
  pushToOmniture({
    ...COMMON_OMNITURE_VARIABLES,
    event: OMNITURE_CATEGORIES.CTA_CLICK,
    cta: {
      name: socialName,
      type: 'click',
      componentName: 'footer',
    },
  });
};

const Footer = () => {
  useIntersectionObserver(
    'footer_section',
    ([entry]) => {
      if (entry.isIntersecting) {
        pushToOmniture({
          ...COMMON_OMNITURE_VARIABLES,
          event: OMNITURE_CATEGORIES.CTA_LOAD,
          cta: {
            name: 'footer_section',
            type: 'load',
            componentName: 'footer',
          },
        });
      }
    },
    [],
    { rootMargin: '-50% 0px' },
  );

  return (
    <FooterStyled className="footer_section">
      <LandingPageContainer>
        <FooterTop>
          <ConnectNameLogo />
          <FooterTopRight>
            <p>{GET_THE_APP}</p>
            <ul>
              <li>
                <a
                  href={EXTERNAL_URLS.APP_STORE}
                  target="_blank"
                  onClick={() => handleSocialClick('app_store')}
                >
                  <img src={AppStore} alt="app-store" />
                </a>
              </li>
              <li>
                <a
                  href={EXTERNAL_URLS.PLAY_STORE}
                  target="_blank"
                  onClick={() => handleSocialClick('play_store')}
                >
                  <img src={PlayStore} alt="play-store" />
                </a>
              </li>
            </ul>
          </FooterTopRight>
        </FooterTop>
        <FooterBody>
          {CONTENT_CONFIG.footerSection.map((section, index) => {
            return (
              <FooterLinks
                key={index}
                title={section.title}
                links={section.links}
                address={section.address}
              />
            );
          })}
        </FooterBody>
      </LandingPageContainer>
    </FooterStyled>
  );
};

export default Footer;
