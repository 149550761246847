import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  GoIbiboLogo,
  MMTLogo,
  MyBizLogoLarge,
  MyPartnerLogoLarge,
  ScrollDown,
  VisibilityIcon,
} from 'assets/modules/landing';
import Ticker from 'components/cosmos-components/Ticker';
import {
  Banner,
  BannerInner,
  BannerLeft,
  BannerTitleWrap,
  BannerTagLine,
  BannerRight,
  BannerRightOuter,
  SignUpButton,
  SignInForm,
  SignUpText,
  // LocationTag,
  FormHeading,
  ForgotPasswordBtn,
  ErrorMessage,
  BannerPattern,
  BannerRightContainer,
  PartnersContainer,
  LocationContainer,
  ScrollDownIcon,
} from './PageHeader.styles';
import { LandingPageContainer } from 'modules/landingPage/LandingPage.styles';
import {
  EMAIL_NOT_FOUND_ERROR_CODE,
  INCORRECT_CREDS_ERROR_CODE,
  TICKER_ITEMS,
  COMMON_OMNITURE_VARIABLES,
} from 'modules/landingPage/constants';
import CreateAccount from '../CreateAccount';
import CustomModal from 'components/cosmos-components/CustomModal';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import CustomToast from 'components/cosmos-components/CustomToast';
import {
  useForgotPassword,
  useLoginUser,
  useVerifyUserEmail,
} from '../../api/landingPageReactQueryHooks';
import useStrings from 'modules/landingPage/hooks/useStrings';
import { ControlledCustomInputField } from 'components/cosmos-components/CustomInputField';
// import { emailRegex } from 'utilities/allRegex';
import { showMessage } from 'utilities/Utils';
import { OMNITURE_CATEGORIES, pushToOmniture } from 'utilities/gtm';
import { useIntersectionObserver } from 'utilities/CustomHooks';

const INITIAL_FORM_DATA = {
  userName: '',
  password: '',
};

interface PageHeaderSectionProps {
  pageHeaderRef: React.RefObject<{ toggleSignUpModal: () => void }>;
}

const isExistingUser = true;

const PageHeaderSection = ({ pageHeaderRef }: PageHeaderSectionProps) => {
  const [STRINGS, COMMON] = useStrings();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: INITIAL_FORM_DATA,
  });
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] =
    useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(
    window.innerHeight <= 700,
  );

  const { userName } = watch();

  const {
    // isSuccess: isExistingUser,
    error: verifyUserError,
    refetch: handleVerifyUser,
  } = useVerifyUserEmail({ email: userName });
  const { mutate: handleUserLogin, error: loginFailureError } = useLoginUser();
  const {
    mutate: sendResetPasswordLink,
    isSuccess: resetPasswordLinkSent,
    data: { response: { internalRequest: isStaffUser = false } = {} } = {},
  } = useForgotPassword();

  const isWrongCredentials =
    loginFailureError?.response?.errorDetail?.errorCode ===
    INCORRECT_CREDS_ERROR_CODE;

  const onSubmit = async formData => {
    if (isExistingUser) {
      handleUserLogin(formData);
    } else {
      await handleVerifyUser();
    }
  };

  const onError = errors => {
    const formErrorLabels = isExistingUser
      ? Object.values(errors)
        .map(({ message }) => message)
        .join(',')
      : errors.userName?.message;

    if (!formErrorLabels) {
      return;
    }

    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      loadedComponents: formErrorLabels,
      cta: {
        name: isExistingUser ? 'sign_in_failure' : 'continue_failure',
        type: 'click',
        componentName: isExistingUser
          ? 'log_in_account'
          : 'sign_in_or_create_account',
      },
    });
  };

  const handleForgotPasswordClick = () => {
    setForgotPasswordModalVisible(true);

    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'forgot_password',
        type: 'click',
        componentName: 'log_in_account',
      },
    });
  };

  const handleSignUpClick = () => {
    setIsSignUpModalVisible(true);

    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'sign_up',
        type: 'click',
        componentName: 'log_in_account',
      },
    });
  };

  const handleViewPasswordClick = () => {
    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'view_password',
        type: 'click',
        componentName: 'log_in_account',
      },
    });
  };

  const handleCreateAccountModalClose = () => {
    const confirmationLinkSent = sessionStorage.getItem(
      'confirmation_link_sent',
    );

    setIsSignUpModalVisible(false);

    if (confirmationLinkSent) {
      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        cta: {
          name: 'close',
          type: 'click',
          componentName: 'confirmation_link_sent',
        },
      });
      sessionStorage.removeItem('confirmation_link_sent');
    } else {
      pushToOmniture({
        ...COMMON_OMNITURE_VARIABLES,
        event: OMNITURE_CATEGORIES.CTA_CLICK,
        cta: {
          name: 'close',
          type: 'click',
          componentName: 'create_account',
        },
      });
    }
  };

  const handleForgotPasswordModalClose = () => {
    setForgotPasswordModalVisible(false);

    pushToOmniture({
      ...COMMON_OMNITURE_VARIABLES,
      event: OMNITURE_CATEGORIES.CTA_CLICK,
      cta: {
        name: 'close',
        type: 'click',
        componentName: 'forgot_password',
      },
    });
  };

  useEffect(() => {
    if (!verifyUserError) {
      return;
    }

    const { errorCode, errorMessage } = verifyUserError;

    if (errorCode && errorCode === EMAIL_NOT_FOUND_ERROR_CODE) {
      setIsSignUpModalVisible(true);
    } else {
      showMessage({
        type: 'error',
        show: true,
        commonMessage: COMMON.MESSAGES.SOMETHING_WENT_WRONG,
        message: errorMessage,
      });
    }
  }, [COMMON.MESSAGES.SOMETHING_WENT_WRONG, verifyUserError]);

  useEffect(() => {
    if (resetPasswordLinkSent && !isStaffUser) {
      setShowToast(true);
      setForgotPasswordModalVisible(false);
    }
  }, [isStaffUser, resetPasswordLinkSent]);

  // TODO: Uncomment this when Email Verification flow is enabled
  // useEffect(() => {
  //   if (isExistingUser) {
  //     pushToOmniture({
  //       ...COMMON_OMNITURE_VARIABLES,
  //       event: OMNITURE_CATEGORIES.CTA_LOAD,
  //       cta: {
  //         name: 'log_in_load',
  //         type: 'load',
  //         componentName: 'log_in_account',
  //       },
  //     });
  //   }
  // }, [isExistingUser]);

  // TODO: Uncomment this when Email Verification flow is enabled
  // useEffect(() => {
  //   const { errorCode } = verifyUserError || {};
  //   const isSuccessFlow =
  //     isExistingUser || errorCode === EMAIL_NOT_FOUND_ERROR_CODE;

  //   if (isSuccessFlow) {
  //     const loadedComponents = isExistingUser ? 'existing_user' : 'new_user';

  //     pushToOmniture({
  //       ...COMMON_OMNITURE_VARIABLES,
  //       event: OMNITURE_CATEGORIES.CTA_CLICK,
  //       loadedComponents,
  //       cta: {
  //         name: 'continue_success',
  //         type: 'click',
  //         componentName: 'sign_in_or_create_account',
  //       },
  //     });
  //   }
  // }, [isExistingUser, verifyUserError]);

  // useIntersectionObserver(
  //   'login_section',
  //   ([entry]) => {
  //     if (entry.isIntersecting) {
  //       pushToOmniture({
  //         ...COMMON_OMNITURE_VARIABLES,
  //         event: OMNITURE_CATEGORIES.CTA_LOAD,
  //         cta: {
  //           name: 'login_section',
  //           type: 'load',
  //           componentName: 'login',
  //         },
  //       });
  //     }
  //   },
  //   [],
  //   { rootMargin: '-50% 0px' },
  // );

  useIntersectionObserver(
    'login_section',
    ([entry]) => {
      if (!entry.isIntersecting) {
        setShowScrollDown(false);
      }
    },
    [],
  );

  useImperativeHandle(
    pageHeaderRef,
    () => ({
      toggleSignUpModal: () => setIsSignUpModalVisible(prevState => !prevState),
    }),
    [],
  );

  return (
    <Banner className="login_section">
      <LandingPageContainer height="100%">
        <BannerInner>
          {showScrollDown && <ScrollDownIcon src={ScrollDown} />}
          <BannerPattern isExistingUser={isExistingUser} />
          <BannerLeft>
            <BannerTitleWrap>
              <span>List your</span> <Ticker items={TICKER_ITEMS} />
            </BannerTitleWrap>
            <p>for free & grow your business</p>
            <BannerTagLine>{STRINGS.pageHeader.subHeading}</BannerTagLine>
            <PartnersContainer>
              <MMTLogo />
              <GoIbiboLogo />
              <MyBizLogoLarge />
              <MyPartnerLogoLarge />
            </PartnersContainer>
            <LocationContainer>
              <p>{STRINGS.pageHeader.caption}</p>
            </LocationContainer>
            {/* <LocationTag>{STRINGS.pageHeader.location_tag}</LocationTag> */}
          </BannerLeft>
          <BannerRightContainer>
            <BannerRightOuter>
              <BannerRight>
                {isExistingUser ? (
                  <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <FormHeading>{STRINGS.loginForm.heading}</FormHeading>
                    <h3>{STRINGS.loginForm.subHeading}</h3>
                    <SignInForm>
                      <ControlledCustomInputField
                        label={STRINGS.FORM_LABELS.EMAIL_CUM_USERNAME}
                        placeholder={
                          STRINGS.FORM_PLACEHOLDERS.EMAIL_CUM_USERNAME
                        }
                        // type="email"
                        name="userName"
                        control={control}
                        defaultValue={userName}
                        rules={{
                          required: COMMON.MESSAGES.VALID_EMAIL_TEXT,
                          // pattern: {
                          //   value: emailRegex,
                          //   message: STRINGS.FORM_VALIDATIONS.EMAIL,
                          // },
                        }}
                      />
                      <ControlledCustomInputField
                        label={STRINGS.FORM_LABELS.PASSWORD}
                        placeholder={STRINGS.FORM_PLACEHOLDERS.PASSWORD}
                        name="password"
                        control={control}
                        type="password"
                        endIcon={
                          <span onClick={handleViewPasswordClick}>
                            <VisibilityIcon />
                          </span>
                        }
                        footerActionComponent={
                          <ForgotPasswordBtn>
                            <a onClick={handleForgotPasswordClick}>
                              {STRINGS.loginForm.forgot_password}
                            </a>
                          </ForgotPasswordBtn>
                        }
                        rules={{ required: COMMON.MESSAGES.VALID_PWD_TEXT }}
                      />
                      {isWrongCredentials && (
                        <ErrorMessage>
                          {STRINGS.loginForm.error_msg}
                        </ErrorMessage>
                      )}
                      <div>
                        <SignUpButton type="submit">
                          {STRINGS.loginForm.button_text}
                        </SignUpButton>
                        <SignUpText>
                          {STRINGS.loginForm.new_to_connect}&nbsp;
                          <a onClick={handleSignUpClick}>
                            {STRINGS.loginForm.sign_in}
                          </a>
                        </SignUpText>
                      </div>
                    </SignInForm>
                  </form>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <FormHeading>{STRINGS.signinForm.heading}</FormHeading>
                    <SignInForm marginTop={32}>
                      <ControlledCustomInputField
                        // type="email"
                        label={STRINGS.FORM_LABELS.EMAIL}
                        placeholder={STRINGS.FORM_PLACEHOLDERS.EMAIL}
                        name="userName"
                        control={control}
                        rules={{
                          required: COMMON.MESSAGES.VALID_EMAIL_TEXT,
                          // pattern: {
                          //   value: emailRegex,
                          //   message: STRINGS.FORM_VALIDATIONS.EMAIL,
                          // },
                        }}
                      />
                      <SignUpButton type="submit">
                        {STRINGS.signinForm.button_text}
                      </SignUpButton>
                    </SignInForm>
                  </form>
                )}
              </BannerRight>
            </BannerRightOuter>
          </BannerRightContainer>
        </BannerInner>
      </LandingPageContainer>
      <CustomModal
        visible={isSignUpModalVisible}
        padding={'16px 32px 12px 32px'}
        closeIcon
        onClose={handleCreateAccountModalClose}
        closeOnOverlayClick={true}
      >
        <CreateAccount email={userName} />
      </CustomModal>
      <CustomModal
        width={'466px'}
        padding={'24px'}
        visible={forgotPasswordModalVisible}
        closeIcon
        onClose={handleForgotPasswordModalClose}
        closeOnOverlayClick={true}
      >
        <ForgotPassword email={userName} onSubmit={sendResetPasswordLink} />
      </CustomModal>
      <CustomToast
        visible={showToast}
        message={STRINGS.pageHeader.passwordResetLinkSent}
        onClose={() => setShowToast(false)}
      />
    </Banner>
  );
};

export default PageHeaderSection;
